/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { FiSearch, FiX } from 'react-icons/fi';

import {
  Flex,
  Box,
  IconButton,
  Alert,
  AlertIcon,
  Tag,
  Tooltip,
  Text,
  Divider,
  TooltipProps,
  Placement,
  Input,
} from '@chakra-ui/react';

interface Props {
  loading?: boolean;
  results?: number;
  searchValue(value: string): void;
  searchClear(): void;
  isVisible?(visible: boolean): void;
  tooltipPlacement?: Placement;
  subject?: string;
}

const Search: React.FC<Props> = ({
  loading = false,
  results,
  searchValue,
  searchClear,
  tooltipPlacement,
  isVisible,
  subject,
}) => {
  const [visible, setVisible] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (visible) {
      inputRef.current?.focus();
    }
  }, [visible]);

  const debounce = useCallback((func: Function, wait: number): Function => {
    // eslint-disable-next-line no-var
    var timeout: any;

    return function executedFunction(...args: number[]) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }, []);

  const returnedFunction = debounce(() => {
    /* Aqui podemos inserir a lógica
    | para realizar as buscas em
    |  nosso back-end.
    */

    // console.log('passou');

    searchValue(inputRef.current?.value || '');
  }, 800);

  const handleVisible = useCallback(() => {
    if (visible) {
      searchClear();
      if (inputRef.current) inputRef.current.value = '';
    }
    setVisible(!visible);
    !!isVisible && isVisible(!visible);
  }, [visible, isVisible, searchClear]);

  return (
    <Flex flexDir="column" width="full">
      <Flex alignSelf={['unset', 'flex-end']}>
        <Input
          // transition="display 0.8s ease-in"
          display={visible ? 'flex' : 'none'}
          visibility={visible ? 'visible' : 'hidden'}
          w={['100%', '350px']}
          // as="input"
          placeholder="Digite sua pesquisa..."
          py={2}
          px={4}
          // bg="gray.200"
          // color="gray.900"
          // borderColor="transparent"
          // borderWidth="2px"
          borderRadius={visible ? '5px 0 0 5px' : 'md'}
          // _focus={{ bg: 'white', borderColor: 'blue.400' }}
          ref={inputRef}
          onKeyUp={() => {
            returnedFunction();
          }}
        />
        <Tooltip
          hasArrow
          aria-label={
            visible
              ? 'Clique para fechar o campo de busca e limpar a pesquisa realizada'
              : 'Clique para abrir o campo de busca'
          }
          label={
            visible
              ? 'Clique para fechar o campo de busca e limpar a pesquisa realizada'
              : 'Clique para abrir o campo de busca'
          }
          zIndex={1}
          placement={tooltipPlacement}
        >
          <IconButton
            isLoading={loading}
            aria-label="Pesquisar"
            icon={visible ? <FiX /> : <FiSearch />}
            size="lg"
            borderRadius={visible ? '0 5px 5px 0' : 'md'}
            onClick={handleVisible}
          />
        </Tooltip>
      </Flex>
      {!!results && (
        <Box bg="info" mt={8} color="#3182ce" alignSelf="flex-start">
          <Flex alignItems="center">
            <Tag
              mr={2}
              color="#3182ce"
              backgroundColor="blue.100"
              fontSize={['14px', '16px']}
              fontWeight={700}
            >
              {results}
            </Tag>
            <Text fontSize={['14px', '16px']}>
              {results > 1 ? 'Resultados encontrados' : 'Resultado encontrado'}
            </Text>
          </Flex>
        </Box>
      )}
      {subject && !loading && !results && (
        <Alert status="info" variant="left-accent" mt={4}>
          <AlertIcon />
          Nenhum(a)
          {`${subject} foi encontrada, limpe o filtro e tente novamente`}
        </Alert>
      )}
    </Flex>
  );
};

export default Search;
