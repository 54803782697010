import React, { useCallback } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';

import { Flex, Avatar, Image, Text, Button } from '@chakra-ui/react';
import imagotype from 'assets/imagotipo.png';
import { useAuth } from 'hooks/useAuth';
import { translateRole } from 'utils/translateRole';

import { MenuButton } from './styles';

interface HeaderProps {
  showSidebar: boolean;
  toggleSidebar(toggle: boolean): void;
}

const Header: React.FC<HeaderProps> = ({ showSidebar, toggleSidebar }) => {
  const { user } = useAuth();

  const handleShowSidebar = useCallback(() => {
    if (showSidebar) {
      toggleSidebar(false);
    } else {
      toggleSidebar(true);
    }
  }, [showSidebar, toggleSidebar]);

  return (
    <Flex flexDir="column" alignItems="center" p={4}>
      <Flex
        justifyContent={['space-between', 'space-between', 'center']}
        alignItems="center"
      >
        <MenuButton type="button" onClick={handleShowSidebar}>
          {showSidebar ? <FiX size={24} /> : <FiMenu size={24} />}
        </MenuButton>
        <Image
          src={imagotype}
          alt="imagotipo benvir"
          width="160px"
          height="auto"
        />
      </Flex>
      {/* <Avatar name={user.name} size="xl" mt="60px" />
      <Text fontSize="20px" fontWeight="bold" mt={7}>
        {user.name}
      </Text>
      <Text fontWeight="bold">{translateRole(user.role)}</Text> */}
    </Flex>
  );
};

export default Header;
