export function translateRole(role: string) {
  switch (role) {
    case 'admin':
      return 'Administrador';
    case 'consultancy':
      return 'Assessoria';
    case 'broker':
      return 'Corretora';
    default:
      return 'Usuário';
  }
}
