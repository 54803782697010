import styled, { css } from 'styled-components';

interface UploadProps {
  isDragAccept: boolean;
  isDragReject: boolean;
  isFocused: boolean;
  // [key: string]: any;
}

interface UploadMessageProps {
  type?: 'error' | 'success' | 'default';
}

const dragActive = css`
  border-color: #12a454;
`;

const dragReject = css`
  border-color: #e83f5b;
`;

export const DropContainer = styled.div`
  border: 4px dashed #cbd5e0;
  background: #edf2f7;
  border-radius: 5px;
  cursor: pointer;
  transition: height 0.2s ease;
  > p {
    font-size: 20px;
  }
`;

const messageColors = {
  default: '#969cb3',
  error: '#e83f5b',
  success: '#12a454',
};

export const UploadMessage = styled.p<UploadMessageProps>`
  display: flex;
  font-size: 16px;
  line-height: 24px;
  padding: 48px 0;
  color: ${({ type }) => messageColors[type || 'default']};
  justify-content: center;
  align-items: center;
  text-align: center;
`;
