import { extendTheme } from '@chakra-ui/react';

import button from '../color-scheme/blue/button';
import input from '../color-scheme/blue/input';
import select from '../color-scheme/blue/select';
import styles from '../styles';

export default extendTheme({
  styles,
  components: {
    ...input,
    ...button,
    ...select,
  },
});
