/* eslint-disable indent */
import styled, { keyframes, css } from 'styled-components';

const transformAnimation = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

export const GridLayout = styled.div.attrs<{ $active: boolean }>((props) => ({
  $active: props.$active || false,
}))`
  min-height: 100vh;
  display: grid;
  grid-template-columns: var(--sidebar-width) 1fr;

  @media (max-width: 767px) {
    width: 100%;
    grid-template-columns: 0 1fr;
    transition:
      grid-template-columns 0.3s ease,
      width 0.3s ease; /* Transição na propriedade grid-template-columns e na largura */

    //display: none;
    ${(props) =>
      props.$active &&
      css`
        grid-template-columns: 1fr;
        z-index: 3;
      `};
  }

  > #main {
    grid-column-start: 2;
  }
`;

export const FlexSidebar = styled.div.attrs<{ $active: boolean }>((props) => ({
  $active: props.$active || false,
}))`
  height: 100vh;
  z-index: 1;
  overflow-y: auto;
  background: var(--color-primary-light);
  position: fixed;
  width: var(--sidebar-width);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 767px) {
    width: 0;
    overflow-x: hidden;

    //animation: 4s ${transformAnimation};

    ${(props) =>
      props.$active &&
      css`
        width: 100%;

        z-index: 3;
      `}
  }
`;
