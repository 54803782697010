import React, {
  useCallback,
  TableHTMLAttributes,
  HtmlHTMLAttributes,
} from 'react';
import { FiArrowUp, FiArrowDown, FiHelpCircle } from 'react-icons/fi';

import {
  Box,
  Flex,
  Heading,
  IconButton,
  Select,
  Text,
  Center,
  ResponsiveValue,
  Tooltip,
  Tag,
  Tab,
  Table as TableChakra,
  TableContainer,
} from '@chakra-ui/react';
import Loading from 'shared/Loading';
import Pagination, { PaginationProps } from 'shared/Pagination';

import { TheadCustom } from './styles';

export interface THeadProps extends TableHTMLAttributes<HTMLTableElement> {
  title: string;
  help?: string;
  order?: 'ASC' | 'DESC';
  children?: React.ComponentElement<HTMLElement, any>;
  colSpan?: number;
  badge?: number;
  align?: 'center' | 'left' | 'right';
}

interface Props {
  alignHead?:
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'center'
    | 'end'
    | 'justify'
    | 'left'
    | 'match-parent'
    | 'right'
    | 'start';
  tHeadBorder?: boolean;
  theadData?: THeadProps[];
  pagination?: PaginationProps;
  newPage?(page: number): void;
  loading?: boolean;
  isLoadingScroll?: boolean;
  showTotalPage?(totalPages: number): void;
  totalRegistiesTitle?: string;
  id?: string;
  // freezeThead?: boolean;
  noOfLines?: number;
  children: React.ReactNode;
}

const Table: React.FC<Props> = ({
  theadData = [],
  pagination,
  newPage,
  loading = false,
  isLoadingScroll = false,
  showTotalPage,
  totalRegistiesTitle = 'Registros',
  children,
  id,
  alignHead = 'left',
  tHeadBorder = false,
  // freezeThead = false,
  noOfLines,
}) => {
  const handleNewPage = useCallback(
    (page: number) => {
      if (newPage) {
        newPage(page);
      }
    },
    [newPage],
  );

  const handleAlterShowTotalPage = useCallback(
    (showPages: number) => {
      if (showTotalPage) showTotalPage(showPages);
    },
    [showTotalPage],
  );

  return (
    <Box
      pos="relative"
      w="full"
      maxW="full"
      height="auto"
      // overflowX={freezeThead ? 'unset' : 'auto'}
    >
      {isLoadingScroll && loading && (
        <Box zIndex={1111} minHeight={500}>
          <Loading />
        </Box>
      )}
      {showTotalPage && (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          p="15px"
          backgroundColor="gray.100"
        >
          <Heading size="sm" color="gray.800" textTransform="uppercase">
            {totalRegistiesTitle}
          </Heading>

          <Flex
            alignItems="center"
            justifyContent="flex-end"
            ml="15px"
            pl="15px"
            borderLeftWidth={1}
            borderLeftColor="gray.300"
          >
            <Text mr={1} fontSize="13px">
              Mostrar:
            </Text>
            <Select
              name="showTotalPg"
              defaultValue={20}
              backgroundColor="transparent"
              size="sm"
              onChange={(e) => handleAlterShowTotalPage(Number(e.target.value))}
            >
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </Select>
          </Flex>
        </Flex>
      )}
      {(isLoadingScroll || !loading) && (
        <TableChakra
          id={id}
          // as="table"
          w="full"
        >
          {theadData.length > 0 && (
            /* Aqui havia o freeze thead */
            <TheadCustom>
              <Box as="tr">
                {theadData.map((item) =>
                  item?.order ? (
                    <Box
                      borderWidth={tHeadBorder ? 1 : 0}
                      as="th"
                      key={item.title}
                      textAlign={item?.align || alignHead}
                      colSpan={item?.colSpan}
                    >
                      <Box>
                        <IconButton
                          variant="ghost"
                          color="gray.500"
                          aria-label={item.order === 'ASC' ? 'DESC' : 'ASC'}
                          title="Order"
                          icon={
                            item.order === 'ASC' ? (
                              <FiArrowUp />
                            ) : (
                              <FiArrowDown />
                            )
                          }
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      as="th"
                      key={item.title}
                      textAlign={
                        item.title === 'Ações'
                          ? 'right'
                          : item?.align || alignHead
                      }
                      borderWidth={tHeadBorder ? 2 : 0}
                      colSpan={item?.colSpan}
                    >
                      {item.help ? (
                        <Flex flexDir="column">
                          <Flex>
                            <Box minWidth="10px" mr={2}>
                              <FiHelpCircle size={15} />
                            </Box>
                            <Tooltip
                              label={item?.help}
                              hasArrow
                              placement="top"
                            >
                              <Text
                                noOfLines={noOfLines}
                                ml={1}
                                lineHeight={1.4}
                              >
                                {item.title}
                              </Text>
                            </Tooltip>
                            {item.badge && (
                              <Tooltip
                                label="Quantidade Total Realizada"
                                hasArrow
                                placement="top"
                              >
                                <Flex ml={7}>
                                  <Tag
                                    borderRadius="10rem"
                                    color="#ffffff"
                                    bgColor="#3a3a3a"
                                    height="15px"
                                  >
                                    <Text fontSize="10px">{item.badge}</Text>
                                  </Tag>
                                </Flex>
                              </Tooltip>
                            )}
                          </Flex>
                          {item.children && (
                            <Flex justifyContent="end">
                              <Tooltip
                                label="Tempo Total Realizado e Tempo Medio Realizado"
                                hasArrow
                                placement="bottom"
                                width="200px"
                                justifyContent="end"
                              >
                                {item.children}
                              </Tooltip>
                            </Flex>
                          )}
                        </Flex>
                      ) : (
                        <Flex flexDir="column">
                          <Flex>
                            <Text
                              noOfLines={noOfLines}
                              maxWidth="175px"
                              lineHeight={1.4}
                            >
                              {item.title}
                            </Text>
                            {item.badge && (
                              <Tooltip
                                label="Quantidade Total Realizada"
                                hasArrow
                                placement="top"
                              >
                                <Flex ml={5}>
                                  <Tag
                                    borderRadius="10rem"
                                    color="#ffffff"
                                    bgColor="#3a3a3a"
                                    height="15px"
                                    ml={5}
                                  >
                                    {item.badge}
                                  </Tag>
                                </Flex>
                              </Tooltip>
                            )}
                          </Flex>
                          {item.children && <Box mt={3}>{item.children}</Box>}
                        </Flex>
                      )}
                    </Box>
                  ),
                )}
              </Box>
            </TheadCustom>
          )}

          <Box
            as="tbody"
            w="full"
            fontSize="md"
            borderTopWidth={1}
            borderBottomWidth={4}
          >
            {children}
          </Box>
        </TableChakra>
      )}
      {!isLoadingScroll && loading && (
        <Flex w="full" h="250px" justifyContent="center" alignItems="center">
          <div>
            <Loading />
          </div>
        </Flex>
      )}

      {pagination && !loading && (
        <Pagination
          current_page={pagination.current_page}
          next={pagination.next}
          prev={pagination.prev}
          total={pagination.total}
          last_page={pagination.last_page}
          per_page={pagination.per_page}
          setPage={(pg) => handleNewPage(pg)}
        />
      )}
    </Box>
  );
};

export default Table;
