import React from 'react';

import {
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialog as AlertDialogChakra,
  AlertDialogProps as AlertDialogPropsChakra,
  Button,
  Text,
} from '@chakra-ui/react';

interface AlertDialogProps extends Omit<AlertDialogPropsChakra, 'children'> {
  title: string;
  description: string;
  action?: string;
  submitButtonColorScheme?: string;
  onSubmit(): void;
  obs?: string;
  isLoading: boolean;
}

const AlertDialog: React.FC<AlertDialogProps> = ({
  title,
  description,
  action = 'Apagar',
  submitButtonColorScheme = 'red',
  onSubmit,
  isOpen,
  onClose,
  isLoading = false,
  leastDestructiveRef,
  obs,
  ...rest
}) => (
  <AlertDialogChakra
    isOpen={isOpen}
    leastDestructiveRef={leastDestructiveRef}
    onClose={onClose}
    {...rest}
  >
    <AlertDialogOverlay>
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          {title}
        </AlertDialogHeader>

        <AlertDialogBody>
          {description}

          <Text color="gray.700" fontSize="small" mt={4}>
            {obs}
          </Text>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            colorScheme={submitButtonColorScheme}
            onClick={onSubmit}
            ml={3}
            isLoading={isLoading}
          >
            {action}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogOverlay>
  </AlertDialogChakra>
);

export default AlertDialog;
