import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import {
  Alert,
  AlertIcon,
  Box,
  Button,
  HStack,
  Td,
  Text,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { parseISO, format } from 'date-fns';
import { Broker } from 'modules/brokers/@types/broker';
import api from 'services/api';
import AlertDialog from 'shared/AlertDialog';
import Loading from 'shared/Loading';
import PageHeader from 'shared/PageHeader';
import { PaginationProps } from 'shared/Pagination';
import Search from 'shared/Search';
import Table, { THeadProps } from 'shared/Table';
import { cnpjMask } from 'utils/masks';

interface BrokerPaginate {
  data: Broker[];
  meta: PaginationProps;
}

const Brokers: React.FC = () => {
  const deleteRef = useRef(null);
  const addToast = useToast();
  const [brokers, setBrokers] = useState<BrokerPaginate>({} as BrokerPaginate);
  const [selectedBroker, setSelectedBroker] = useState<Broker | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const thead: THeadProps[] = [
    { title: 'ID' },
    { title: 'Nome' },
    { title: 'CNPJ' },
    { title: 'Data de Registro' },
    { title: 'Ações', align: 'right' },
  ];

  useEffect(() => {
    setLoading(true);

    api
      .get<BrokerPaginate>('brokers', {
        params: {
          page,
          search,
        },
      })
      .then((resp) => {
        setBrokers({
          ...resp.data,
          data: resp.data.data.map((broker) => ({
            ...broker,
            cnpj: cnpjMask(broker.cnpj),
          })),
        });
      })
      .finally(() => setLoading(false));
  }, [page, search]);

  const handleDeleteBroker = useCallback(
    (id: number) => {
      setLoadingDelete(true);

      api
        .delete(`users/${id}`)
        .then(() => {
          setBrokers((state) => ({
            meta: {
              ...state.meta,
              total: state.meta.total - 1,
            },
            data: state.data.filter((broker) => broker.user_id !== id),
          }));

          onClose();
          addToast({
            title: 'Corretora deletada com sucesso',
            status: 'success',
            position: 'top-right',
            isClosable: true,
          });
        })
        .catch((err) => {
          addToast({
            title: 'Não foi possível deletar a corretora',
            description: err.response?.data?.message || '',
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        })
        .finally(() => setLoadingDelete(false));
    },
    [addToast, onClose],
  );

  return (
    <>
      <PageHeader title="Corretores" description="Lista de corretores">
        <Button colorScheme="blue" onClick={() => navigate('cadastrar')}>
          <FaPlus />
          <Text ml={2}>Cadastrar</Text>
        </Button>
      </PageHeader>
      <Box mb={4}>
        <Search
          results={search ? brokers.meta.total : 0}
          searchValue={(value) => {
            setPage(1);
            // console.log(value);
            setSearch(value);
          }}
          searchClear={() => {
            setPage(1);
            setSearch('');
          }}
          tooltipPlacement="top-start"
        />
      </Box>
      {loading ? (
        <Loading />
      ) : (
        <Box>
          {!brokers?.data?.length ? (
            <Alert status="info">
              <AlertIcon />
              Nenhuma corretora encontrada
            </Alert>
          ) : (
            <Table
              theadData={thead}
              loading={loading}
              pagination={{
                current_page: brokers.meta.current_page,
                last_page: brokers.meta.last_page,
                next: brokers.meta.next,
                prev: brokers.meta.prev,
                per_page: brokers.meta.per_page,
                total: brokers.meta.total,
              }}
              newPage={(pg) => setPage(pg)}
            >
              {brokers.data.map((broker) => (
                <Tr key={broker.user_id}>
                  <Td>
                    <Text>{broker.user_id}</Text>
                  </Td>
                  <Td>
                    <Text>{broker.corporate_reason}</Text>
                  </Td>
                  <Td>
                    <Text>{broker.cnpj}</Text>
                  </Td>
                  <Td>
                    <Text>
                      {format(
                        parseISO(broker.created_at),
                        "dd/MM/yy 'às' HH:mm",
                      )}
                    </Text>
                  </Td>
                  <Td>
                    <HStack spacing={2}>
                      <Button
                        type="button"
                        colorScheme="yellow"
                        variant="outline"
                        size="sm"
                        onClick={() => navigate(`editar/${broker.user_id}`)}
                      >
                        <FaEdit />
                      </Button>
                      <Button
                        type="button"
                        colorScheme="red"
                        variant="outline"
                        size="sm"
                        onClick={() => {
                          setSelectedBroker(broker);
                          onOpen();
                        }}
                        isLoading={loadingDelete}
                      >
                        <FaTrash />
                      </Button>
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Table>
          )}
        </Box>
      )}
      {selectedBroker && (
        <AlertDialog
          leastDestructiveRef={deleteRef}
          title="Apagar corretora"
          description={`A corretora ${selectedBroker.corporate_reason} e todos os seus dados de usuário no sistema serão apagados`}
          obs="Essa é uma ação irreversível"
          isOpen={isOpen}
          onSubmit={() => handleDeleteBroker(selectedBroker.user_id)}
          onClose={() => setSelectedBroker(null)}
          isLoading={loadingDelete}
        />
      )}
    </>
  );
};

export default Brokers;
