import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Tr,
  Td,
  Flex,
  Button,
  Text,
  Box,
  useToast,
  Stack,
  Divider,
  AbsoluteCenter,
  useDisclosure,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { filesize } from 'filesize';
import { Form, Formik, FormikHelpers } from 'formik';
import { useAuth } from 'hooks/useAuth';
import {
  Condominium,
  Policy,
  PolicyFile,
  Proposal,
} from 'modules/quotes/@types/condominium';
import api from 'services/api';
import FileCard from 'shared/FileCard';
import Input from 'shared/Input';
import Loading from 'shared/Loading';
import Modal from 'shared/Modal';
import PageHeader from 'shared/PageHeader';
import Select from 'shared/Select';
import Table from 'shared/Table';
import Upload from 'shared/Upload';
import { FileExtension } from 'utils/getFileIconWithType';
import { cepMask, cnpjMask, moneyMask, phoneMask } from 'utils/masks';
import * as Yup from 'yup';

interface FormData {
  serial: string;
  start_date: string;
  end_date: string;
  status_id: string;
}

export interface UploadFileProps {
  file: File | null;
  name: string;
  extension: FileExtension;
  mimetype: string;
  readable_size: string;
  original_size?: number;
  // preview: string;
}

export interface FileProps extends UploadFileProps {
  id: number;
  drive_id: string;
  progress?: number;
  progress_completed?: boolean;
  error?: boolean;
  message?: string;
}

const ShowBusiness: React.FC = () => {
  /* Adicionar estado com condominium tendo proposal e policy como objeto */
  const { user } = useAuth();
  const refDownload = useRef(null);

  const isAdmin = user.role === 'admin';

  const addToast = useToast();
  const [condominium, setCondominium] = useState<Condominium>(
    {} as Condominium,
  );
  const [policy, setPolicy] = useState<Policy>({} as Policy);
  const [proposal, setProposal] = useState<Proposal>({} as Proposal);
  const statusOptions = [
    { name: 'Ativo', value: 3 },
    { name: 'Pendente', value: 4 },
    { name: 'Cancelado', value: 5 },
  ];
  const { id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const [loading, setLoading] = useState(true);
  const [initialData, setInitialData] = useState<FormData>({
    serial: '',
    start_date: '',
    end_date: '',
    status_id: '',
  });
  const [proposalFile, setProposalFile] = useState<FileProps>();
  const [policyFile, setPolicyFile] = useState<FileProps>();
  const [paymentBookletFile, setPaymentBookletFile] = useState<FileProps>();
  const [cancellationFile, setCancellationFile] = useState<FileProps>();
  const navigate = useNavigate();

  useEffect(() => {
    api
      .get<Condominium>(`condominiums/${id}`)
      .then((resp) => {
        const cond = resp.data;
        const { proposals } = cond;

        if (!proposals.length || !proposals[0]) return;

        setProposal(proposals[0]);

        const { policies } = proposals[0];

        if (!policies?.length || !policies[0]) return;

        setPolicy({ ...policies[0], phone: phoneMask(policies[0].phone) });

        const { serial, start_date, end_date, status_id, policy_files } =
          policies[0];

        if (policy_files.length > 0) {
          policy_files.forEach((pf) => {
            const ext = pf.name.substring(pf.name.indexOf('.') + 1);

            const dataFile: FileProps = {
              id: pf.id,
              name: pf.name,
              mimetype: pf.mimetype,
              file: null,
              readable_size: filesize(pf.size),
              original_size: pf.size,
              extension: ext as FileExtension,
              drive_id: pf.drive_id,
            };

            // console.log(dataFile);

            switch (pf.type) {
              case 'policy':
                setPolicyFile(dataFile);
                break;
              case 'payment_booklet':
                setPaymentBookletFile(dataFile);
                break;
              case 'cancellation':
                setCancellationFile(dataFile);
                break;
              case 'proposal':
                setProposalFile(dataFile);
                break;
              default:
            }
          });
        }

        // console.log(start_date);
        setCondominium({
          ...cond,
          cnpj: cnpjMask(cond.cnpj),
          cep: cepMask(cond.cep),
        });
        setInitialData({
          serial: serial || '',
          start_date: start_date || '',
          end_date: end_date || '',
          status_id: status_id?.toString() || '',
        });

        /* Setar arquivos já salvos em seus respectivos estados, se não for
        modificado não é necessário reenvia-lo, pois
        dessa maneira não temos os dados da posição file,
        necessário para o envio, talvez se realizar um stream dos dados */
      })
      .catch((err) => {
        addToast({
          title: 'Não foi possível obter as informações do condomínio',
          description: err.response?.data?.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });

        navigate('/meus-negocios');
      })
      .finally(() => setLoading(false));
  }, [id, addToast, navigate]);

  const handleSubmit = useCallback(
    async (values: FormData, actions: FormikHelpers<FormData>) => {
      try {
        await api.put(`policies/${policy?.id}`, values);

        addToast({
          title: 'Informações da apólice atualizadas com sucesso',
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });

        // console.log(data);
      } catch (err: any) {
        addToast({
          title:
            'Não foi possível atualizar as informações da apólice, tente novamente mais tarde',
          description: err.response?.data.message || '',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      } finally {
        actions.setSubmitting(false);
      }
    },
    [addToast, policy],
  );

  const handleUploadFile = useCallback((file: File) => {
    // console.log();

    const ext = file.name.substring(file.name.indexOf('.') + 1);
    const extFile = ext as FileExtension;

    const uploadFiles: UploadFileProps = {
      file,
      name: file.name,
      extension: extFile,
      mimetype: file.type,
      readable_size: filesize(file.size),
      original_size: file.size,
      // preview: URL.createObjectURL(file),
    };

    // console.log(uploadFiles);

    return uploadFiles;
  }, []);

  const handleCreateUpdateFile = useCallback(
    async (
      fileData: UploadFileProps,
      type: string,
      fileId?: number,
    ): Promise<PolicyFile | null> => {
      const data = new FormData();

      // console.log(fileId);

      data.append('policy_id', policy.id.toString());
      data.append('type', type);

      if (fileData?.file) data.append('file', fileData.file);

      try {
        const { data: dataStoredFile } = fileId
          ? await api.put<PolicyFile>(`policy-files/${fileId}`, data)
          : await api.post<PolicyFile>('policy-files', data);

        // Adicionar carregamento

        addToast({
          title: `Arquivo ${fileData.name} salvo com sucesso`,
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });

        return dataStoredFile;
      } catch (err: any) {
        addToast({
          title: 'Não foi possível salvar o arquivo',
          description: err.response?.data.message || '',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });

        return null;
      }
    },
    [addToast, policy.id],
  );

  const handleUploadPolicyFile = useCallback(
    async (files: File[]) => {
      if (!files.length) return;

      setLoadingUpload(true);
      onOpen();
      // Verifica quando o arquivo já está salvo, e deve-se apenas atualizar
      const upFile = handleUploadFile(files[0]);

      const data = await handleCreateUpdateFile(
        upFile,
        'policy',
        policyFile?.id,
      );

      if (data) {
        setPolicyFile({ ...upFile, id: data.id, drive_id: data.drive_id });
      }

      setLoadingUpload(false);
      onClose();
    },
    [handleUploadFile, handleCreateUpdateFile, policyFile, onOpen, onClose],
  );

  const handleUploadPaymentBookletFile = useCallback(
    async (files: File[]) => {
      if (!files.length) return;

      const upFile = handleUploadFile(files[0]);

      const data = await handleCreateUpdateFile(
        upFile,
        'payment_booklet',
        paymentBookletFile?.id,
      );

      if (data) {
        setPaymentBookletFile({
          ...upFile,
          id: data.id,
          drive_id: data.drive_id,
        });
      }
    },
    [handleUploadFile, handleCreateUpdateFile, paymentBookletFile],
  );

  const handleUploadCancellationFile = useCallback(
    async (files: File[]) => {
      if (!files.length) return;

      const upFile = handleUploadFile(files[0]);

      const data = await handleCreateUpdateFile(
        upFile,
        'cancellation',
        cancellationFile?.id,
      );

      if (data) {
        setCancellationFile({
          ...upFile,
          id: data.id,
          drive_id: data.drive_id,
        });
      }
    },
    [handleUploadFile, handleCreateUpdateFile, cancellationFile],
  );

  const handleDownloadFile = useCallback(
    async (fileId: number, name: string, driveId: string) => {
      setLoadingDownload(true);
      onOpen();

      await api
        .get(`policy-files/download/${driveId}`, { responseType: 'blob' })

        .then((resp) => {
          const urlBlob = window.URL.createObjectURL(new Blob([resp.data]));

          const downloadLink = document.createElement('a');

          downloadLink?.setAttribute('href', urlBlob);
          downloadLink?.setAttribute('download', name);

          document.body.appendChild(downloadLink);

          downloadLink?.click();

          downloadLink.remove();
        })
        .catch((err) => {
          addToast({
            title: 'Erro ao realizar download do arquivo',
            description: err.response?.data.message || '',
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        })
        .finally(() => {
          setLoadingDownload(false);
          onClose();
        });
    },
    [addToast, onOpen, onClose],
  );

  const validation = Yup.object().shape({
    serial: Yup.string().required('Código de apólice obrigatório'),
    start_date: Yup.string().required(
      'Início da vigência da apólice é obrigatório',
    ),
    end_date: Yup.string().required(
      'Término da vigência da apólice é obrigatório',
    ),
    status_id: Yup.string().required('Status da apólice obrigatório'),
  });

  return (
    <>
      <PageHeader
        title="Meus negócios" // Exibir nome do condôminio
        description="Visão geral de todos os seus negócios"
        goBackPage
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          <Flex flexDir={['column', 'row']} mb={6}>
            <Text fontSize="3xl" fontWeight="bold">
              {condominium?.name}
            </Text>
            <Text fontSize="3xl" fontWeight="bold" mt={[2, 0]} ml={[0, 8]}>
              {condominium?.cnpj}
            </Text>
          </Flex>
          <Formik
            initialValues={initialData}
            onSubmit={handleSubmit}
            validationSchema={isAdmin && validation}
          >
            {({ touched, errors, isSubmitting }) => (
              <Form>
                <Stack
                  flexDir={['column', 'row']}
                  /* mb={[0, 4]} */
                  mb={4}
                  justifyContent="space-between"
                  spacing={4}
                >
                  <Input
                    label="Nº da Apólice"
                    name="serial"
                    placeholder="Nº da apólice"
                    error={errors.serial}
                    touched={touched.serial}
                    isReadOnly={!isAdmin}
                    variant={isAdmin ? 'outline' : 'reading'}
                  />
                  <Input
                    label="Seguradora"
                    name="insurance"
                    placeholder="Seguradora"
                    value="American Life Seguros"
                    isReadOnly
                    variant="reading"
                    bg="white"
                  />
                  <Input
                    label="Valor mensal total"
                    name="total_monthly_value"
                    placeholder="Valor mensal total"
                    value={`R$ ${moneyMask(proposal.total_monthly_value.toString())}`}
                    isReadOnly
                    variant="reading"
                  />
                </Stack>
                <Stack
                  flexDir={['column', 'row']}
                  mb={4}
                  justifyContent="space-between"
                  spacing={4}
                >
                  <Input
                    type="date"
                    label="Início da vigência"
                    name="start_date"
                    placeholder="Início da vigência"
                    error={errors.start_date}
                    touched={touched.start_date}
                    isReadOnly={!isAdmin}
                    variant={isAdmin ? 'outline' : 'reading'}
                  />
                  <Input
                    type="date"
                    label="Fim da vigência"
                    name="end_date"
                    placeholder="Fim da vigência"
                    error={errors.end_date}
                    touched={touched.end_date}
                    isReadOnly={!isAdmin}
                    variant={isAdmin ? 'outline' : 'reading'}
                  />
                  <Select
                    label="Situação"
                    name="status_id"
                    options={statusOptions}
                    error={errors.status_id}
                    touched={touched.status_id}
                    isReadOnly={!isAdmin}
                    isDisabled={!isAdmin}
                    // userSelect={!isAdmin ? 'none' : 'auto' }
                  />
                </Stack>
                <Stack
                  flexDir={['column', 'row']}
                  mb={4}
                  justifyContent="space-between"
                  spacing={4}
                >
                  <Input
                    label="CEP"
                    name="cep"
                    placeholder="CEP"
                    value={condominium.cep}
                    isReadOnly
                    variant="reading"
                  />
                  <Input
                    label="Endereço"
                    name="adress"
                    placeholder="Endereço"
                    value={condominium.address}
                    isReadOnly
                    variant="reading"
                  />
                  <Input
                    label="Cidade"
                    name="city"
                    placeholder="Cidade"
                    value={condominium.city}
                    isReadOnly
                    variant="reading"
                  />
                  <Input
                    label="UF"
                    name="uf"
                    placeholder="UF"
                    value={condominium.uf}
                    isReadOnly
                    variant="reading"
                  />
                </Stack>
                <Stack
                  flexDir={['column', 'row']}
                  mb={4}
                  justifyContent="space-between"
                  spacing={4}
                >
                  <Input
                    label="Nome do síndico"
                    name="condominium_manager_name"
                    placeholder="Nome do síndico"
                    value={policy.condominium_manager_name}
                    isReadOnly
                    variant="reading"
                  />
                  <Input
                    label="E-mail"
                    name="email"
                    placeholder="E-mail"
                    value={policy.email}
                    isReadOnly
                    variant="reading"
                  />
                  <Input
                    label="Telefone"
                    name="phone"
                    placeholder="Telefone"
                    value={policy.phone}
                    isReadOnly
                    variant="reading"
                  />
                </Stack>
                <Flex
                  flexDir={['column', 'row']}
                  justifyContent="space-between"
                  alignItems="center"
                  mt={6}
                >
                  <Box>
                    <Flex
                      mb={4}
                      flex={1}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Text whiteSpace="nowrap">
                        Quantidade de unidades padrão
                      </Text>
                      <Box width="85px">
                        <Input
                          name="qty_standard_units"
                          value={condominium.unit_info.qty_standard_units}
                          isReadOnly
                          variant="reading"
                          ml={4}
                          textAlign="center"
                        />
                      </Box>
                    </Flex>
                    {!!condominium.unit_info?.qty_private_coverages && (
                      <Flex alignItems="center" justifyContent="space-between">
                        <Text whiteSpace="nowrap">
                          Quantidade de unidades cobertura
                        </Text>
                        <Box width="85px">
                          <Input
                            name="qty_private_coverages"
                            value={condominium.unit_info.qty_private_coverages}
                            isReadOnly
                            variant="reading"
                            ml={4}
                            textAlign="center"
                          />
                        </Box>
                      </Flex>
                    )}
                  </Box>

                  <Flex alignItems="center" mt={[4, 0]}>
                    <Text whiteSpace="nowrap">Valor comissão mensal</Text>
                    <Input
                      name="comission"
                      value={`R$ ${moneyMask(proposal.comission.toString())}`}
                      isReadOnly
                      variant="reading"
                      ml={4}
                    />
                  </Flex>
                </Flex>

                {/* <Input
                label="Seguradora"
                name="insurance"
                defaultValue="American Life"
                mb={4}
                width="150px"
                isReadOnly
              />
              <Input
                label="Cód. da Apólice"
                name="serial"
                placeholder="Cód da Apólice"
                error={errors.serial}
                touched={touched.serial}
                mask="decimal"
                mb={4}
                width="150px"
              /> */}
                {isAdmin && (
                  <Flex flexDir="row-reverse" mt={6}>
                    <Button
                      type="submit"
                      colorScheme="green"
                      isLoading={isSubmitting}
                      width="120px"
                    >
                      Salvar
                    </Button>
                  </Flex>
                )}
              </Form>
            )}
          </Formik>

          <Box mt={4} mb={6}>
            <Text fontSize="2xl" fontWeight="bold">
              Arquivos
            </Text>

            <Divider borderColor="gray.300" />
          </Box>

          <Box mb={4}>
            {proposalFile && (
              <>
                <Text fontWeight="bold">Proposta</Text>
                <Box>
                  <FileCard
                    name={proposalFile.name}
                    size={proposalFile.readable_size}
                    extension={proposalFile.extension}
                    mimetype={proposalFile.mimetype}
                    onDownload={() =>
                      handleDownloadFile(
                        proposalFile.id,
                        proposalFile.name,
                        proposalFile?.drive_id,
                      )
                    }
                  />
                </Box>
              </>
            )}
          </Box>
          <Box>
            <Text fontWeight="bold">Apólice: </Text>
            {isAdmin && (
              <Upload
                accept={{
                  'application/pdf': ['.pdf'],
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                    ['.docx'],
                }}
                showFormat
                maxFiles={1}
                onUpload={(files) => handleUploadPolicyFile(files)}
              />
            )}

            {!!policyFile && (
              <Box>
                <FileCard
                  name={policyFile.name}
                  size={policyFile.readable_size}
                  extension={policyFile.extension}
                  mimetype={policyFile.mimetype}
                  onDownload={() =>
                    handleDownloadFile(
                      policyFile.id,
                      policyFile.name,
                      policyFile?.drive_id,
                    )
                  }
                />
              </Box>
            )}

            {!isAdmin && !policyFile && (
              <Alert status="info" mt={2}>
                <AlertIcon />
                Nenhum arquivo de apólice foi encontrado
              </Alert>
            )}
          </Box>

          <Box mt={4}>
            <Text fontWeight="bold">Carnê: </Text>
            {isAdmin && (
              <Upload
                accept={{
                  'application/pdf': ['.pdf'],
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                    ['.docx'],
                }}
                showFormat
                maxFiles={1}
                onUpload={(files) => handleUploadPaymentBookletFile(files)}
              />
            )}

            {!!paymentBookletFile && (
              <Box>
                <FileCard
                  name={paymentBookletFile.name}
                  size={paymentBookletFile.readable_size}
                  extension={paymentBookletFile.extension}
                  mimetype={paymentBookletFile.mimetype}
                  onDownload={() =>
                    handleDownloadFile(
                      paymentBookletFile.id,
                      paymentBookletFile.name,
                      paymentBookletFile.drive_id,
                    )
                  }
                />
              </Box>
            )}

            {!isAdmin && !paymentBookletFile && (
              <Alert status="info" mt={2}>
                <AlertIcon />
                Nenhum carnê foi encontrado
              </Alert>
            )}
          </Box>

          <Box mt={4}>
            <Text fontWeight="bold">Cancelamento: </Text>
            {isAdmin && (
              <Upload
                accept={{
                  'application/pdf': ['.pdf'],
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                    ['.docx'],
                }}
                showFormat
                maxFiles={1}
                onUpload={(files) => handleUploadCancellationFile(files)}
              />
            )}
            {!!cancellationFile && (
              <Box ref={refDownload}>
                <FileCard
                  name={cancellationFile.name}
                  size={cancellationFile.readable_size}
                  extension={cancellationFile.extension}
                  mimetype={cancellationFile.mimetype}
                  onDownload={() =>
                    handleDownloadFile(
                      cancellationFile.id,
                      cancellationFile.name,
                      cancellationFile.drive_id,
                    )
                  }
                />
              </Box>
            )}
            {!isAdmin && !paymentBookletFile && (
              <Alert status="info" mt={2}>
                <AlertIcon />
                Nenhum arquivo de cancelamento foi encontrado
              </Alert>
            )}
          </Box>
          <Modal
            title=""
            isOpen={isOpen}
            onClose={onClose}
            modalFooter={false}
            size="sm"
            hiddenCloseButton
            finalFocusRef={refDownload}
          >
            <Flex minH="200px" alignItems="center" justifyContent="center">
              <Loading />
            </Flex>
          </Modal>
        </>
      )}
    </>
  );
};

export default ShowBusiness;
