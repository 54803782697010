import styled, { keyframes } from 'styled-components';

const spinAnimation = keyframes`

    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  
`;

const LoadingLogo = styled.img`
  animation: ${spinAnimation} 1s infinite;
`;

export default LoadingLogo;
