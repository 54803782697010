import React, { useEffect, useState } from 'react';

import { Stack, HStack, Text, Box, Flex } from '@chakra-ui/react';
import api from 'services/api';
import Loading from 'shared/Loading';
import PageHeader from 'shared/PageHeader';
import { moneyMask } from 'utils/masks';

interface PolicyStatus {
  id: number;
  name: string;
  color: string;
  count_policies: number;
}

interface DashboardProps {
  policies_status: PolicyStatus[];
  monthly_acumulatted_comission: number;
}

const Dashboard: React.FC = () => {
  const [policiesStatus, setPoliciesStatus] = useState<PolicyStatus[]>();
  const [activesPoliciesTotalComission, setActivesPolicesTotalComission] =
    useState(0);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get<DashboardProps>('dashboard')
      .then((resp) => {
        const { policies_status, monthly_acumulatted_comission } = resp.data;

        setPoliciesStatus(policies_status);
        setActivesPolicesTotalComission(monthly_acumulatted_comission);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <PageHeader
        title="Meu Painel"
        description="Visão geral de todos os seus negócios"
      />

      {loading ? (
        <Loading />
      ) : (
        <Box display="inline-block">
          <Text fontSize="3xl" mb={6} fontWeight="600">
            Apólices
          </Text>
          <HStack spacing="60px" flexDir={['column', 'row']}>
            {!!policiesStatus?.length &&
              policiesStatus.map((ps) => (
                <Box
                  width="144px"
                  height="122px"
                  p={2}
                  key={ps.id}
                  borderTopRightRadius="20px"
                  borderBottomRightRadius="20px"
                  borderBottomLeftRadius="20px"
                  /* borderRadius="20px" */
                  bg="gray.100"
                  boxShadow="md"
                  position="relative"
                  // bg={`${ps.color}.600`}
                  borderWidth={1}
                >
                  <Flex
                    justifyContent="space-between"
                    position="absolute"
                    left={2}
                    top={2}
                    width="full"
                  >
                    {/* Todos os status tem o plural terminado em s */}
                    <Text
                      fontSize="md"
                      // color={`${ps.color}.600`}
                    >
                      {`${ps.name}s`}
                    </Text>
                  </Flex>
                  <Flex
                    flexDir="column"
                    alignItems="center"
                    justifyContent="center"
                    height="full"
                    // borderColor={`${ps.color}.600`}
                  >
                    <Text
                      fontSize="32px"
                      fontWeight="600"
                      fontFamily="Open Sans system-ui, sans-serif"
                      // color={`${ps.color}.600`}
                    >
                      {ps.count_policies}
                    </Text>
                  </Flex>
                </Box>
              ))}
          </HStack>
          <Box mt="60px" width="full">
            <Flex>
              <Text fontSize="3xl" fontWeight="600">
                Comissão acumulada
              </Text>
              <Text
                ml={2}
                fontSize="3xl"
                fontWeight="600"
                display={['none', 'block']}
              >
                mensal
              </Text>
            </Flex>

            <Flex
              height="64px"
              width="full"
              mt={6}
              fontSize="32px"
              fontWeight="600"
              fontFamily="Open Sans system-ui, sans-serif"
              bg="gray.100"
              textAlign="center"
              borderWidth={1}
              /* borderTopRightRadius="20px"
              borderBottomRightRadius="20px"
              borderBottomLeftRadius="20px" */
              borderRadius="20px"
              alignItems="center"
              justifyContent="center"
              boxShadow="md"
              // color="green.400"
            >
              {`R$ ${moneyMask(activesPoliciesTotalComission.toString())}`}
            </Flex>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Dashboard;
