import React, { useCallback } from 'react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

import {
  Modal as ModalChakra,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  ModalProps,
  Flex,
} from '@chakra-ui/react';

interface Props extends ModalProps {
  title: string;
  titleButtonCancel?: string;
  titleButtonConfirm?: string;
  colorScheme?: string;
  onSubmit?(): void;
  isLoading?: boolean;
  buttonLeft?: () => void;
  buttonRight?: () => void;
  modalFooter?: boolean;
  children: React.ReactNode;
  hiddenCloseButton?: boolean;
}

const Modal: React.FC<Props> = ({
  title,
  titleButtonCancel,
  titleButtonConfirm,
  colorScheme,
  onSubmit,
  isLoading = false,
  buttonLeft,
  buttonRight,
  isOpen,
  onClose,
  size,
  preserveScrollBarGap = false,
  children,
  modalFooter = true,
  hiddenCloseButton = false,
  ...rest
}) => (
  <ModalChakra
    preserveScrollBarGap={false || preserveScrollBarGap}
    isOpen={isOpen}
    onClose={onClose}
    size={size || '2xl'}
    {...rest}
  >
    <ModalOverlay />
    <ModalContent borderRadius="sm">
      <ModalHeader>{title}</ModalHeader>
      {buttonLeft && buttonRight && (
        <Flex>
          <Button
            onClick={buttonLeft}
            position="absolute"
            left="0"
            top="50%"
            rounded="100%"
            mr={3}
          >
            <FiArrowLeft />
          </Button>
          <Button
            onClick={buttonRight}
            position="absolute"
            left="0"
            top="50%"
            rounded="100%"
            mr={3}
          >
            <FiArrowRight />
          </Button>
        </Flex>
      )}
      {!hiddenCloseButton && <ModalCloseButton />}

      <ModalBody>{children}</ModalBody>

      {modalFooter && (
        <ModalFooter>
          {!isLoading && (
            <Button
              variant="ghost"
              colorScheme="gray"
              mr={3}
              onClick={onClose}
              rounded="100%"
            >
              {titleButtonCancel || 'Fechar'}
            </Button>
          )}

          <Button
            type={onSubmit ? 'button' : 'submit'}
            variant="solid"
            colorScheme={colorScheme || 'blue'}
            isLoading={isLoading}
            isDisabled={isLoading}
            onClick={() => onSubmit && onSubmit()}
          >
            {titleButtonConfirm || 'Confirmar'}
          </Button>
        </ModalFooter>
      )}
    </ModalContent>
  </ModalChakra>
);

export default Modal;
