import { Link } from 'react-router-dom';

import { Box } from '@chakra-ui/react';
import styled from 'styled-components';

export const LinkItemMenu = styled(Link)`
  width: 100%;
`;

export const BoxMenu = styled(Box)`
  margin-top: 1.25rem;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 1.5rem;
  margin: 0 0.5rem;
  border-radius: 1rem;
  margin-top: 45px;

  div.item-menu + div.item-menu {
    margin-top: 1rem;
  }

  div.item-menu a div {
    height: 2rem;
  }

  /* > div.item-menu svg {
    height: 16px;
    width: 16px;
  } */

  > div.item-menu:hover a div {
    background-color: #00000014;
    border-radius: 1rem;
    //height: 2rem;
  }

  > div.item-menu a div.active {
    background-color: var(--color-primary);
    border-radius: 1rem;
  }
`;
