import React from 'react';
import { FiDownload } from 'react-icons/fi';

import { Box, Flex, IconButton, Image, Text } from '@chakra-ui/react';
// import download from 'utils/downloadFile';
import {
  FileExtension,
  getPreviewFile,
  getFileIconByExt,
} from 'utils/getFileIconWithType';

import { Files, LinkDownload } from './styles';

interface FileCardProps {
  name: string;
  mimetype: string;
  extension: FileExtension;
  size: string;
  onDownload(): void;
}

const FileCard: React.FC<FileCardProps> = ({
  name,
  extension,
  mimetype,
  size,
  onDownload,
}) => (
  <Files>
    {/*  <Flex alignItems="center" marginTop="1.5rem" marginBottom="0.5rem">
      <Text color="#262626" fontWeight="600" fontSize="lg">
        Arquivos
      </Text>
    </Flex> */}
    <Box id="containerFiles" mt="1.5rem">
      <LinkDownload>
        <Flex
          flexDir={['column', 'row']}
          className="item-file"
          alignItems="center"
          justifyContent="space-between"
          boxShadow="0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);"
          borderRadius="0.5rem"
          p="1.1rem"
          position="relative"
        >
          {/*  <a href={fileDir} target="__blank"> */}
          <Image
            minWidth="34px"
            width="34px"
            mb={[3, 0]}
            src={getFileIconByExt(extension)}
            objectFit="cover"
            cursor="pointer"
          />
          {/* </a> */}
          {/* <a href={fileDir} target="__blank"> */}
          <Flex
            className="img-text-file"
            alignItems="center"
            minH="80px"
            ml={4}
          >
            <Box className="info-text-file">
              <Text noOfLines={2} maxWidth="220px">
                {name}
              </Text>
              <Text fontSize="xs">{size}</Text>
            </Box>
          </Flex>
          {/* </a> */}
          <IconButton
            className="button-download"
            aria-label="Download"
            icon={<FiDownload />}
            size="md"
            height="2.1rem"
            minWidth="2.1rem"
            background="transparent"
            position={['absolute', 'initial']}
            right="5px"
            top="5px"
            onClick={() => onDownload()}
          />
        </Flex>
      </LinkDownload>
    </Box>
  </Files>
);

export default FileCard;
