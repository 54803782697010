import React, { useCallback, useState } from 'react';

import {
  Box,
  Button,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stat,
  StatHelpText,
} from '@chakra-ui/react';

export interface PaginationProps {
  current_page: number;
  last_page: number;
  per_page: number;
  next: number;
  prev: number;
  total: number;
}

interface Props extends PaginationProps {
  setPage(page: number): void;
}

const Pagination: React.FC<Props> = ({
  current_page,
  next,
  prev,
  total,
  last_page,
  per_page,
  setPage,
}) => {
  const [selectGoPage, setSelectGoPage] = useState(1);
  const handleNewPage = useCallback(
    (page: number) => {
      // window.scrollTo(0, 0);
      setPage(page);
    },
    [setPage],
  );

  return (
    <Box
      display="flex"
      flexDir="row"
      justifyContent="space-between"
      alignItems="center"
      p="10px"
      pt={4}
      fontSize="sm"
      color="gray.500"
    >
      <Box>
        <Stat>
          <StatHelpText>{`${current_page < last_page ? per_page * current_page : total} de ${total} registros`}</StatHelpText>
        </Stat>
      </Box>

      {total > per_page && (
        <>
          <Flex alignItems="center" justifyContent="center">
            {current_page > 1 && (
              <Button size="xs" onClick={() => handleNewPage(1)} mx={1}>
                1
              </Button>
            )}
            {current_page > 3 && <span>...</span>}
            {current_page > 2 && (
              <Button
                size="xs"
                onClick={() => handleNewPage(current_page - 1)}
                mx={1}
              >
                {current_page - 1}
              </Button>
            )}

            <Button colorScheme="blue" size="sm" mx={1}>
              {current_page}
            </Button>

            {current_page < last_page && (
              <>
                {current_page + 1 < last_page && (
                  <Button
                    size="xs"
                    onClick={() => handleNewPage(current_page + 1)}
                    mx={1}
                  >
                    {current_page + 1}
                  </Button>
                )}
                {current_page + 2 < last_page && <span>...</span>}
                <Button
                  size="xs"
                  onClick={() => handleNewPage(last_page)}
                  mx={1}
                >
                  {last_page}
                </Button>
              </>
            )}
          </Flex>

          <Flex>
            <Box w={70} mr={1}>
              <NumberInput
                size="sm"
                defaultValue={current_page}
                min={1}
                max={last_page}
                onChange={(pg) => setSelectGoPage(Number(pg))}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Box>
            <Button size="sm" onClick={() => handleNewPage(selectGoPage)}>
              Ir
            </Button>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default Pagination;
