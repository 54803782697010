/* eslint-disable no-param-reassign */
import axios, { AxiosError } from 'axios';

import {
  getLocalAccessToken,
  getLocalRefreshToken,
  getUser,
  updateLocalAccessToken,
} from './token';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API,
  /* headers: {
    'Content-Type': 'application/json',
  }, */
});

// Verificar se isso faz com que os requests sejam duplicados
instance.interceptors.request.use(
  (config) => {
    const token = getLocalAccessToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (res) => res,
  async (err) => {
    // console.log('erro de conexão', err);

    if (err instanceof AxiosError && err.code === 'ERR_NETWORK') {
      alert('Erro de conexão. Verifique sua conexão e tente novamente!');
    }

    const originalConfig = err.config;

    // console.log('original config', originalConfig);
    // Atual rota de login no backend se chama login
    if (originalConfig?.url !== 'login' && err.response) {
      // Access Token was expired (401 - Unauthorized)
      // A flag retry evita um loop infinnito no caso de a solicitação falhar novamente
      // e o servidor continuar retornando 401 no código de status.

      // console.log(originalConfig?.retrySignal);
      // O controle para loop infinito está falhando
      if (err.response.status === 401 && !originalConfig.retrySignal) {
        // Verificar se algum momento precisa setar como true
        originalConfig.retrySignal = true;

        // console.log(getLocalRefreshToken());

        try {
          // Passando o token através do header, o backend sabe tratar
          // , porém o front não está enviando certo

          // const rs = await instance.get('refresh', {
          //   headers: {
          //     Authorization: `Bearer ${getLocalRefreshToken()}`,
          //   },
          // });
          const rs = await instance.post('refresh', {
            user_id: getUser()?.id,
            refresh_token: getLocalRefreshToken(),
          });

          const { accessToken } = rs.data;
          updateLocalAccessToken(accessToken);

          return instance(originalConfig);
        } catch (error) {
          return Promise.reject(error);
        }
      }
    }

    return Promise.reject(err);
  },
);

export default instance;
