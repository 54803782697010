import axios from 'axios';
import { FormikErrors } from 'formik';

interface ViaCepResponse {
  cep: string;
  logradouro: string;
  complemento?: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge?: string;
  gia?: string;
  ddd: string;
  siafi?: string;
  erro?: boolean;
}

interface AdressInfo {
  cep: string;
  city: string;
  uf: string;
  address: string;
}

type setValue = (
  value: any,
  shouldValidate?: boolean | undefined,
) => Promise<void | FormikErrors<FormData>>;

export default async function getAddress(
  cep: string,
): Promise<AdressInfo | null> {
  const cepNumber = cep.replace(/\D+/g, '');

  if (!cepNumber || cepNumber.length !== 8) return null;

  let addressInfo: AdressInfo | null = null;

  const resp = await axios.get<ViaCepResponse>(
    `https://viacep.com.br/ws/${cepNumber}/json/`,
  );

  const { bairro, logradouro, complemento, uf, erro } = resp.data;

  if (erro) {
    return null;
  }

  addressInfo = {
    cep,
    city: bairro,
    uf,
    address: `${logradouro || ''}  ${complemento ? `, ${complemento}` : ''}`,
  };

  // console.log(addressInfo);

  return addressInfo;
}
