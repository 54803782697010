import React, { useEffect, useState } from 'react';
import { FaEdit, FaEye, FaPlus, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import {
  Alert,
  AlertIcon,
  Box,
  Button,
  HStack,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { useAuth } from 'hooks/useAuth';
import { Condominium } from 'modules/quotes/@types/condominium';
import api from 'services/api';
import Loading from 'shared/Loading';
import PageHeader from 'shared/PageHeader';
import { PaginationProps } from 'shared/Pagination';
import Search from 'shared/Search';
import Table, { THeadProps } from 'shared/Table';
import { cnpjMask } from 'utils/masks';

interface BusinessPaginate {
  data: Condominium[];
  meta: PaginationProps;
}

const Business: React.FC = () => {
  const { user } = useAuth();

  const isAdmin = user.role === 'admin';

  const navigate = useNavigate();
  const [condominiums, setCondominiums] = useState<BusinessPaginate>(
    {} as BusinessPaginate,
  );
  const [loading, setLoading] = useState(true);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const thead: THeadProps[] = [
    { title: 'ID' },
    { title: 'Nome' },
    { title: 'CNPJ' },
    { title: 'Situação' },
    { title: 'Data de Registro' },
    { title: 'Ações', align: 'right' },
  ];

  useEffect(() => {
    setLoading(true);

    const { CancelToken } = axios;
    const source = CancelToken.source();

    api
      .get<BusinessPaginate>('condominiums', {
        params: {
          page,
          search,
          only_assured: 1,
        },
        cancelToken: source.token,
      })
      .then((resp) => {
        setCondominiums({
          ...resp.data,
          data: resp.data.data.map((consult) => ({
            ...consult,
            cnpj: cnpjMask(consult.cnpj),
          })),
        });
      })
      .finally(() => setLoading(false));

    return () => {
      /* Após chamar novamente a api, sem que se tenha resposta da primeira solicitação (pendente)
          o pedido anterior é cancelado e se realiza um novo pedido */
      source.cancel('Operação cancelada pelo usuário');
    };
  }, [page, search]);

  return (
    <>
      <PageHeader
        title="Meus negócios"
        description="Visão geral de todos os seus negócios"
      />
      <Box mb={4}>
        <Search
          loading={loadingSearch}
          results={search ? condominiums.meta.total : 0}
          searchValue={(value) => {
            setPage(1);
            // console.log(value);
            setSearch(value);
          }}
          searchClear={() => {
            setPage(1);
            setSearch('');
          }}
          tooltipPlacement="top-start"
        />
      </Box>
      {loading ? (
        <Loading />
      ) : (
        <Box>
          {!condominiums?.data?.length ? (
            <Alert status="info">
              <AlertIcon />
              Nenhuma cotação encontrada
            </Alert>
          ) : (
            <Table
              theadData={thead}
              loading={loading}
              pagination={{
                current_page: condominiums.meta.current_page,
                last_page: condominiums.meta.last_page,
                next: condominiums.meta.next,
                prev: condominiums.meta.prev,
                per_page: condominiums.meta.per_page,
                total: condominiums.meta.total,
              }}
              newPage={(pg: number) => setPage(pg)}
            >
              {condominiums.data.map((cond) => (
                <Tr key={cond.id}>
                  <Td>
                    <Text>{cond.id}</Text>
                  </Td>
                  <Td>
                    <Text>{cond.name}</Text>
                  </Td>
                  <Td>
                    <Text>{cond.cnpj}</Text>
                  </Td>
                  {/* Trazer informação do BD */}
                  <Td>
                    {!!cond?.proposals.length &&
                      !!cond.proposals[0].policies?.length &&
                      cond.proposals[0]?.policies[0]?.status?.name}
                  </Td>
                  <Td>
                    <Text>
                      {format(parseISO(cond.created_at), "dd/MM/yy 'às' HH:mm")}
                    </Text>
                  </Td>
                  <Td>
                    <HStack spacing={2}>
                      <Button
                        type="button"
                        colorScheme={isAdmin ? 'yellow' : 'blue'}
                        variant="outline"
                        size="sm"
                        onClick={() => navigate(`${cond.id}`)}
                      >
                        {isAdmin ? <FaEdit /> : <FaEye />}
                      </Button>
                      {/* <Button
                        type="button"
                        colorScheme="red"
                        variant="outline"
                        size="sm"
                        onClick={() => {
                          setSelectedConsultancy(cond);
                          onOpen();
                        }}
                        isLoading={loadingDelete}
                      >
                        <FaTrash />
                      </Button> */}
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Table>
          )}
        </Box>
      )}
    </>
  );
};

export default Business;
