export default {
  Button: {
    colorScheme: {
      brand: {
        backgroundColor: '#B9E7E4',
        color: '#000',
      },
    },
  },
};
