import React, { useMemo } from 'react';

import {
  Select as SelectChakra,
  SelectProps as SelectChakraProps,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';
import { Field, FieldInputProps, FormikProps } from 'formik';

export interface Option {
  name: string;
  value: string | number;
}

interface SelectProps extends SelectChakraProps {
  name: string;
  label?: string;
  placeholder?: string;
  options: Option[];
  height?: string | number;
  error?: string;
  touched?: boolean;
}

interface FormData {
  [key: string]: string;
}

const Select: React.FC<SelectProps> = ({
  name,
  label,
  placeholder = 'Selecione uma opção',
  options,
  height,
  error,
  touched,
  ...rest
}) => {
  const hasError = useMemo(() => !!error && touched, [error, touched]);

  return (
    <Field name={name}>
      {({
        field,
        form,
      }: {
        field: FieldInputProps<string>;
        form: FormikProps<FormData>;
      }) => (
        <FormControl isInvalid={hasError}>
          {label && <FormLabel>{label}</FormLabel>}

          {/* LeftInput */}

          <SelectChakra
            {...field}
            {...rest}
            onChange={(el) => {
              field.onChange(el);
            }}
          >
            <option value="">{placeholder}</option>
            {options.map((op) => (
              <option key={op.value} value={op.value}>
                {op.name}
              </option>
            ))}
          </SelectChakra>

          {hasError && <FormErrorMessage>{error}</FormErrorMessage>}
        </FormControl>
      )}
    </Field>
  );
};

export default Select;
