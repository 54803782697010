import React from 'react';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import { Box, ChakraProvider, Text } from '@chakra-ui/react';
import { AuthProvider } from 'hooks/useAuth';
import NotFound from 'modules/auth/pages/NotFound';
import Brokers from 'modules/brokers/pages/Brokers';
import CreateBroker from 'modules/brokers/pages/CreateBroker';
import Business from 'modules/business/pages/Business';
import ShowBusiness from 'modules/business/pages/ShowBusiness';
import Consultancies from 'modules/consultancies/pages/Consultancies';
import CreateConsultancy from 'modules/consultancies/pages/CreateConsultancy';
import Dashboard from 'modules/dashboard/pages/Dashboard';
import CalculateQuote from 'modules/quotes/pages/CalculateQuote';
import Quotes from 'modules/quotes/pages/Quotes';
import Settings from 'modules/settings/pages/Settings';
import ProtectedLayout from 'shared/layouts/ProtectedLayout';

import SignIn from './modules/auth/pages/Signin';
import theme from './styles/themes/theme';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<SignIn />} />
      <Route element={<ProtectedLayout />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="corretores" element={<Brokers />} />
        <Route path="corretores/cadastrar" element={<CreateBroker />} />
        <Route path="corretores/editar/:id" element={<CreateBroker />} />
        <Route path="assessorias" element={<Consultancies />} />
        <Route path="assessorias/cadastrar" element={<CreateConsultancy />} />
        <Route path="assessorias/editar/:id" element={<CreateConsultancy />} />
        <Route path="cotacoes" element={<Quotes />} />
        <Route path="cotacoes/nova" element={<CalculateQuote />} />
        <Route path="cotacoes/editar/:id" element={<CalculateQuote />} />
        <Route path="meus-negocios" element={<Business />} />
        <Route path="meus-negocios/:id" element={<ShowBusiness />} />
        <Route path="configuracoes" element={<Settings />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </>,
  ),
);

function App() {
  return (
    <AuthProvider>
      {/* Se for necessário reduzir a carga inicial do JS utilizar o
      ChakraBaseProvider */}
      <ChakraProvider theme={theme}>
        <RouterProvider router={router} />
        {/* <Box backgroundImage={logo} height="500px" /> */}
      </ChakraProvider>
    </AuthProvider>
  );
}

export default App;
