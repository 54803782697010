import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import {
  Box,
  Flex,
  Image,
  Text,
  Stack,
  Button,
  useToast,
} from '@chakra-ui/react';
import benvirPrint from 'assets/benvir-print-blue.png';
import imagotype from 'assets/imagotipo.png';
import { AxiosError } from 'axios';
import { Field, Form, Formik, FieldInputProps, FormikProps } from 'formik';
import { useAuth } from 'hooks/useAuth';
import Input from 'shared/Input';
import * as Yup from 'yup';

interface DataForm {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const { user, signIn } = useAuth();
  const addToast = useToast();
  const navigate = useNavigate();

  async function handleSubmit(data: DataForm, action: any) {
    try {
      await signIn(data.email, data.password);

      navigate('/dashboard');

      action.setSubmitting(false);
    } catch (err: any) {
      // console.log(err);
      addToast({
        title: 'Não foi possível realizar o login',
        description: err.response?.data?.message || '',
        status: 'error',
        position: 'top-right',
      });
    }
  }

  const initialValues: DataForm = { email: '', password: '' };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('E-mail é obrigatório')
      .email('O e-mail deve ser válido'),
    password: Yup.string().required('Senha é obrigatória'),
  });

  function validateField(value: string, field: string) {
    let error;
    if (!value) {
      error = `${field} é obrigatório`;
    }
    return error;
  }

  return user ? (
    <Navigate to="/dashboard" />
  ) : (
    <Flex minHeight="100vh" fontFamily="poppins, system-ui">
      <Box
        backgroundImage={benvirPrint}
        minHeight="full"
        width="full"
        backgroundRepeat="no-repeat"
        bgSize="cover"
      />
      <Flex
        flexDir="column"
        bg="rgba(238, 244, 242)"
        minWidth={['full', '640px']}
        padding={6}
      >
        <Image
          src={imagotype}
          alt="imagotipo benvir"
          width="200px"
          height="auto"
          marginLeft="auto"
        />
        <Flex
          flex={1}
          flexDir="column"
          justifyContent="center"
          alignItems="center"
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(data, action) => handleSubmit(data, action)}
          >
            {(props) => (
              <Form>
                <Box justifySelf="center" width="350px">
                  <Text mb="50px" fontSize="36px" fontWeight={500}>
                    Fazer Login
                  </Text>
                  <Box>
                    <Stack spacing={3} mb={4}>
                      <Input
                        variant="auth"
                        size="lg"
                        name="email"
                        placeholder="E-mail"
                        error={props.errors.email}
                        touched={props.touched.email}
                      />
                      <Input
                        variant="auth"
                        name="password"
                        placeholder="Senha"
                        isPassword
                        error={props.errors.password}
                        touched={props.touched.password}
                      />
                    </Stack>
                    <Button
                      // colorScheme="brand"
                      width="full"
                      type="submit"
                      colorScheme="teal"
                      /* bg="teal.00"
                      _hover={{
                        background: 'teal.700',
                      }} */
                      isLoading={props.isSubmitting}
                      mt={6}
                    >
                      Entrar
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SignIn;
