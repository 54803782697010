import React, { useCallback, useEffect, useState } from 'react';

import { VStack, Flex, Button, Tr, Td, Text, useToast } from '@chakra-ui/react';
import { Formik, Form, FormikHelpers } from 'formik';
import { Coverage, Multiplier } from 'modules/quotes/@types/condominium';
import api from 'services/api';
import Input from 'shared/Input';
import Loading from 'shared/Loading';
import PageHeader from 'shared/PageHeader';
import Table, { THeadProps } from 'shared/Table';
import * as Yup from 'yup';

interface SettingsData {
  multiplier: Multiplier;
  coverages: Coverage[];
}

interface FormDataCoverage {
  id: number;
  fee: string | null;
  multiplier: string | null;
}

interface FormData {
  m2_price: string;
  coverages: FormDataCoverage[];
}

const Settings: React.FC = () => {
  const addToast = useToast();
  const [settings, setSettings] = useState<SettingsData>();
  const [initialData, setIntialData] = useState<FormData>({
    m2_price: '',
    coverages: [],
  });
  const [loading, setLoading] = useState(true);

  // Os dados de cobertura são todos opcionais
  const validation = Yup.object().shape({
    m2_price: Yup.number().required('O preço do m² é obrigatório'),
  });

  const thead: THeadProps[] = [
    {
      title: 'Cobertura',
    },
    {
      title: 'Taxa',
    },
    {
      title: 'Multiplicador',
    },
  ];

  useEffect(() => {
    api
      .get<SettingsData>('condominiums/options')
      .then((resp) => {
        setSettings(resp.data);

        const { multiplier, coverages } = resp.data;

        // console.log('10%', Number('10%'));

        // Multiplicamos por 100 para listar com (%) e dividimos por 100 para salvar sem (%)
        setIntialData({
          m2_price: multiplier.m2_price.toString(),
          coverages: coverages.map((cov) => ({
            id: cov.id,
            fee: cov.fee ? (cov.fee * 100).toFixed(5) : '',
            multiplier: cov.multiplier ? (cov.multiplier * 100).toFixed(0) : '',
          })),
        });
      })
      .finally(() => setLoading(false));
  }, []);

  const handleSubmit = useCallback(
    (data: FormData, action: FormikHelpers<FormData>) => {
      const { m2_price, coverages } = data;

      const formattedCoverages = coverages.map((cov) => ({
        ...cov,
        fee: cov.fee ? (Number(cov.fee) / 100).toFixed(5) : null,
        multiplier: cov.multiplier
          ? (Number(cov.multiplier) / 100).toFixed(5)
          : null,
      }));

      api
        .post('settings', { m2_price, coverages: formattedCoverages })
        .then(() => {
          addToast({
            title: 'Configurações atualizadas com sucesso',
            status: 'success',
            position: 'top-right',
            isClosable: true,
          });
        })
        .catch(() => {
          addToast({
            title: 'Não foi possível atualizar as configurações',
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        })
        .finally(() => action.setSubmitting(false));
    },
    [addToast],
  );

  return (
    <>
      <PageHeader
        title="Configurações"
        description="Defina as métricas para cálculo do seguros"
        goBackPage
      />
      {loading ? (
        <Loading />
      ) : (
        <Formik
          initialValues={initialData}
          onSubmit={handleSubmit}
          validationSchema={validation}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form>
              <Input
                label="Valor do m²"
                name="m2_price"
                placeholder="Valor do m²"
                error={errors.m2_price}
                touched={touched.m2_price}
                mask="decimal"
                mb={4}
                width="150px"
              />
              <Table theadData={thead} loading={loading}>
                {settings?.coverages?.map((cov, key) => (
                  <Tr key={cov.id}>
                    <Td>
                      <Text>{cov.name}</Text>
                    </Td>
                    <Td>
                      <Input
                        name={`coverages[${key}].fee`}
                        // placeholder="Taxa"
                        rightAddon="%"
                        width="150px"
                        // mask="percent"
                      />
                    </Td>
                    <Td>
                      <Input
                        name={`coverages[${key}].multiplier`}
                        // placeholder="Multiplicador"
                        rightAddon="%"
                        width="150px"
                        // mask="percent"
                      />
                    </Td>
                  </Tr>
                ))}
              </Table>
              <Flex flexDir="row-reverse">
                <Button
                  type="submit"
                  colorScheme="green"
                  isLoading={isSubmitting}
                  mt={6}
                  width="120px"
                >
                  Salvar
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default Settings;
