import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FaCheck,
  FaCheckCircle,
  FaChevronCircleLeft,
  FaChevronCircleRight,
  FaChevronRight,
} from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Image,
  List,
  ListIcon,
  ListItem,
  Switch,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import logoBullet from 'assets/benvir-gear-logo.png';
import {
  Field,
  FieldInputProps,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
  setNestedObjectValues,
} from 'formik';
import { useAuth } from 'hooks/useAuth';
import { Broker } from 'modules/brokers/@types/broker';
import {
  Condominium,
  Coverage,
  Multiplier,
} from 'modules/quotes/@types/condominium';
import OptimizedProposalReport from 'modules/quotes/components/OptimizedProposalReport';
import ProposalReport from 'modules/quotes/components/ProposalReport';
import api from 'services/api';
import getAddress from 'services/via-cep';
import Input from 'shared/Input';
import Loading from 'shared/Loading';
import Modal from 'shared/Modal';
import PageHeader from 'shared/PageHeader';
import Select from 'shared/Select';
import Table from 'shared/Table';
import { formatNumber } from 'utils/formatNumber';
import { cnpjMask } from 'utils/masks';
import states from 'utils/states';
import * as Yup from 'yup';

interface Options {
  coverages: Coverage[];
  multiplier: Multiplier;
}

export interface CoverageUnitMonthlyValues {
  standardUnitValue: number;
  privateCoverageUnitValue: number;
  total: number;
  netTotal: number; // Total liquido
  comission: number;
}

export interface CoverageCalc {
  id: number;
  name: string;
  pos?: string;
  lmiStandardUnit: string;
  lmiPrivateCoverageUnit?: string | null;
}

export interface FormData {
  user_id: string;
  avg_m2_standard_units: string;
  qty_standard_units: string;
  avg_m2_private_coverages?: string;
  qty_private_coverages?: string;
  name: string;
  cnpj: string;
  cep: string;
  uf: string;
  city: string;
  address: string;
}

interface FormTransmissionData {
  name: string;
  email: string;
  phone: string;
  accept: boolean;
}

interface BrokerOption extends Broker {
  name: string;
  value: number;
}

const IOF = 0.0738; // 7,38%
const COMISSION = 0.2;

const CalculateQuote: React.FC = () => {
  /* A const id será utilizada somente no useEffect para evitar
  renderizações ao alterar o valor de condominiumId */
  const { id } = useParams();
  const { user } = useAuth();
  const isConsultancy = user.role === 'consultancy';
  const addToast = useToast();
  const navigate = useNavigate();
  const [condominiumId, setCondominiumId] = useState<number | null>(
    id ? Number(id) : null,
  );
  const [brokers, setBrokers] = useState<BrokerOption[]>([]);
  const [proposalId, setProposalId] = useState<number>();
  const [togglePrivateCoverages, setTogglePrivateCoverages] = useState(false);
  const [initialData, setIntialData] = useState<FormData>({
    user_id: !isConsultancy ? user.id.toString() : '',
    avg_m2_standard_units: '',
    qty_standard_units: '',
    avg_m2_private_coverages: '',
    qty_private_coverages: '',
    name: '',
    cnpj: '',
    cep: '',
    uf: '',
    city: '',
    address: '',
  });
  const initialDataTransmission: FormTransmissionData = {
    name: '',
    email: '',
    phone: '',
    accept: false,
  };
  const [currentStep, setCurrentStep] = useState(0);
  const [options, setoptions] = useState<Options>();
  const [coverages, setCoverages] = useState<CoverageCalc[]>([]);
  const [coverageUnitMonthlyValues, setCoverageUnitMonthlyValues] =
    useState<CoverageUnitMonthlyValues>({
      privateCoverageUnitValue: 0,
      standardUnitValue: 0,
      total: 0,
      netTotal: 0,
      comission: 0,
    });
  const [qtyStandardUnits, setQtyStandardUnits] = useState(0);
  const [qtyCoverageUnits, setQtyCoverageUnits] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingOptions, setLoadingOptions] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [proposalFileBlob, setProposalFileBlob] = useState<Blob>();
  const [loadingTransmission, setLoadingTransmission] = useState(false);

  /*   const monthlyValuesStandardUnit: number = 27.4;
  const monthlyValuesCoverageUnit: number = 55.01;

  const totalMonthlyValuesStandardUnit = useMemo(
    () => qtyStandardUnits * monthlyValuesStandardUnit,
    [qtyStandardUnits, monthlyValuesStandardUnit],
  );

  const totalMonthlyValuesCoverageUnit = useMemo(
    () => qtyCoverageUnits * monthlyValuesCoverageUnit,
    [qtyCoverageUnits, monthlyValuesCoverageUnit],
  ); */

  const calculateMonthlyValues = useCallback(
    (value: number) => formatNumber(value),
    [],
  );

  const assistances = [
    [
      'Chaveiro',
      'Encanador',
      'Eletricista',
      'Vidraceiro',
      'Conserto de aparelho telefônico',
      'Desentupimento simples',
      'Conserto de eletrodomésticos',
      'Conserto de ar condicionado',
      'Pet Protection',
    ],
    [
      'Pet funeral assistência',
      'Hospedagem',
      'Limpeza da residência',
      'Mudança e guarda de móveis',
      'Regresso antecipado',
      'Recuperação de veículo',
      'Serv. domésticos provisórios',
      'Cuidado de crianças',
      'Remoção inter hospitalar',
    ],
  ];

  const handleCalculateLmi = useCallback(
    (
      m2: number,
      multiplier: number,
      maxLmi?: number,
      minM2Price?: number,
      maxM2Price?: number,
      coverageMultiplier?: number,
    ) => {
      let lmi = m2 * multiplier;
      let minM2: number;

      // Apenas verificaremos o minM2 ou maxM2Price se tiver maxLmi
      /* Esse tratamento é realizado para quando tiver maxLmi,
      considerarmos o valor do m2 multiplicado (let lmit) */
      if (maxLmi && !minM2Price) {
        minM2 = lmi;
      } else if (maxLmi && minM2Price) {
        minM2 = minM2Price;
      } else {
        minM2 = 0;
      }

      /* No momento consideramos que a cobertura ou terá o mín ou máx m2 price,
      nunca os dois juntos */

      /* Se tiver o max nem verifica o min */
      if (
        maxLmi &&
        ((maxM2Price && lmi <= maxM2Price) ||
          (!maxM2Price && minM2 && lmi >= minM2))
      ) {
        lmi = Number(maxLmi);
      } else if (coverageMultiplier) {
        lmi *= Number(coverageMultiplier);
      }

      /* if (maxLmi && lmi >= maxLmi) {
        lmi = Number(maxLmi);
      } else if (coverageMultiplier) {
        lmi *= Number(coverageMultiplier);
      } */

      return lmi;
    },
    [],
  );

  const handleSubmitTransmission = useCallback(
    async (data: FormTransmissionData) => {
      setLoadingTransmission(true);

      const formattedData = new FormData();

      /*  const formattedData = {
        ...data,
        condominium_manager_name: data.name,
        proposal_id: proposalId,
        phone: data.phone.replace(/\D/g, ''),
      }; */

      if (proposalId) {
        formattedData.append('proposal_id', proposalId?.toString());
      }

      formattedData.append('condominium_manager_name', data.name);
      formattedData.append('phone', data.phone.replace(/\D/g, ''));
      formattedData.append('email', data.email);

      if (proposalFileBlob) {
        formattedData.append(
          'proposal_file',
          proposalFileBlob,
          'Estudo Personalizado.pdf',
        );
      }

      if (coverages.length) {
        coverages.forEach((cov, i) => {
          formattedData.append(
            `standard_coverage_values[${i}][name]`,
            cov.name,
          );
          formattedData.append(
            `standard_coverage_values[${i}][value]`,
            cov.lmiStandardUnit,
          );

          if (togglePrivateCoverages) {
            formattedData.append(
              `private_coverage_values[${i}][name]`,
              cov.name,
            );
            formattedData.append(
              `private_coverage_values[${i}][value]`,
              cov.lmiPrivateCoverageUnit || '',
            );
          }
        });
      }
      // formattedData.append('private_coverage_values')

      formattedData.append(
        'standard_unit_monthly_value',
        coverageUnitMonthlyValues.standardUnitValue.toString(),
      );
      formattedData.append(
        'private_coverage_unit_monthly_value',
        coverageUnitMonthlyValues.privateCoverageUnitValue.toString(),
      );

      api
        .post('policies', formattedData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((resp) => {
          addToast({
            title: 'Seguro contratado com sucesso',
            description:
              'A sua solicitação foi transmitida, em breve finalizaremos a sua apólice e o seu condomínio estará seguro conosco',
            status: 'success',
            position: 'top-right',
            duration: 7000,
            isClosable: true,
          });

          navigate('/meus-negocios');
        })
        .catch((err) => {
          addToast({
            title:
              'Não foi possível contratar o seguro, tente novamente mais tarde',
            description: err.response?.data?.message || '',
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        })
        .finally(() => setLoadingTransmission(false));
    },
    [
      addToast,
      navigate,
      proposalId,
      proposalFileBlob,
      coverages,
      togglePrivateCoverages,
      coverageUnitMonthlyValues,
    ],
  );

  const handleSubmit = useCallback(
    (data: FormData, action: FormikHelpers<FormData>) => {
      const {
        cep,
        cnpj,
        qty_standard_units,
        avg_m2_standard_units,
        avg_m2_private_coverages,
        qty_private_coverages,
      } = data;

      let formattedData;

      try {
        if (!options?.coverages.length) return;

        let standardUnitValue = 0;
        let privateCoverageUnitValue = 0;
        let totalMonthlyValue = 0;

        setCoverages(
          options?.coverages.map((cov) => {
            const lmiStandard = handleCalculateLmi(
              Number(avg_m2_standard_units),
              options.multiplier.m2_price,
              cov?.max_lmi,
              cov?.min_m2_price,
              cov?.max_m2_price,
              cov?.multiplier,
            );

            standardUnitValue += cov.fee ? lmiStandard * cov.fee : lmiStandard;

            let lmiCoverage = null;

            if (togglePrivateCoverages) {
              lmiCoverage = handleCalculateLmi(
                Number(avg_m2_private_coverages),
                options.multiplier.m2_price,
                cov?.max_lmi,
                cov?.min_m2_price,
                cov?.max_m2_price,
                cov?.multiplier,
              );

              privateCoverageUnitValue += cov.fee
                ? lmiCoverage * cov.fee
                : lmiCoverage;
            }

            return {
              id: cov.id,
              name: cov.name,
              pos: cov.pos,
              lmiStandardUnit: formatNumber(lmiStandard),
              lmiPrivateCoverageUnit: lmiCoverage
                ? formatNumber(lmiCoverage)
                : '-',
            };
          }),
        );

        totalMonthlyValue = standardUnitValue * Number(qty_standard_units);

        if (togglePrivateCoverages) {
          totalMonthlyValue +=
            privateCoverageUnitValue * Number(qty_private_coverages);
        }

        const netTotal = totalMonthlyValue / (1 + IOF); // Valor líquido total
        const comission = netTotal * COMISSION;

        setCoverageUnitMonthlyValues({
          standardUnitValue,
          privateCoverageUnitValue,
          total: totalMonthlyValue,
          netTotal,
          comission,
        });

        formattedData = {
          ...data,
          qty_standard_units: Number(qty_standard_units),
          avg_m2_standard_units: Number(avg_m2_standard_units),
          avg_m2_private_coverages: togglePrivateCoverages
            ? Number(avg_m2_private_coverages)
            : null,
          qty_private_coverages: togglePrivateCoverages
            ? Number(qty_private_coverages)
            : null,
          cep: cep.replace(/\D/g, ''),
          cnpj: cnpj.replace(/\D/g, ''),
          standard_unit_monthly_value: standardUnitValue.toFixed(2),
          private_coverage_unit_monthly_value:
            privateCoverageUnitValue > 0
              ? privateCoverageUnitValue.toFixed(2)
              : null,
          total_monthly_value: totalMonthlyValue.toFixed(2),
          comission: comission.toFixed(2),
        };
      } catch (err) {
        addToast({
          title:
            'Ocorreu um erro ao calcular sua proposta, tente novamente mais tarde',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
        return;
      }

      if (!condominiumId) {
        api
          .post<Condominium>('condominiums', formattedData)
          .then((resp) => {
            const { id: newId, proposals } = resp.data;

            setCondominiumId(newId);

            if (proposals.length) setProposalId(proposals[0].id);

            addToast({
              title: 'Cotação salva com sucesso',
              description:
                'Verifique com atenção os dados antes de transmitir a sua solicitação. Se necessário você pode voltar para as etapas anteriores para corrigir as informações',
              duration: 7000,
              isClosable: true,
              status: 'success',
              position: 'top-right',
            });

            setCurrentStep(currentStep + 1);

            // navigate('/cotacoes');
          })
          .catch((err) => {
            addToast({
              title:
                'Não foi possível salvar a cotação, tente novamente mais tarde',
              description: err.response?.data?.message || '',
              status: 'error',
              position: 'top-right',
              isClosable: true,
            });
          })
          .finally(() => action.setSubmitting(false));
      } else {
        api
          .put(`condominiums/${condominiumId}`, {
            ...formattedData,
            proposal_id: proposalId,
          })
          .then((resp) => {
            addToast({
              title: 'Cotação e dados do condomínio atualizados com sucesso',
              status: 'success',
              position: 'top-right',
              isClosable: true,
            });

            setCurrentStep(currentStep + 1);

            // navigate('/cotacoes');
          })
          .catch((err) => {
            addToast({
              title:
                'Não foi possível atualizar a cotação e os dados do condomínio, tente novamente mais tarde',
              description: err.response?.data?.message || '',
              status: 'error',
              position: 'top-right',
              isClosable: true,
            });
          })
          .finally(() => action.setSubmitting(false));
      }
    },
    [
      addToast,
      togglePrivateCoverages,
      condominiumId,
      currentStep,
      handleCalculateLmi,
      options,
      proposalId,
    ],
  );

  const handleGetInfoProposal = useCallback(
    (userId: string) => {
      if (!isConsultancy) return user.name;

      const br = brokers.find(
        (brok) => Number(brok.user_id) === Number(userId),
      );

      return br?.corporate_reason || '';
    },
    [brokers, isConsultancy, user.name],
  );

  const validationUnitInfo = useMemo(() => {
    const msgUserId =
      'Necessário escolher uma corretora para prosseguir com a cotação';

    const defaultSchema = {
      user_id: isConsultancy
        ? Yup.number().required(msgUserId)
        : Yup.number().nullable(),
      avg_m2_standard_units: Yup.number().required('Campo obrigatório'),
      qty_standard_units: Yup.number().required('Campo obrigatório'),
      avg_m2_private_coverages: Yup.number().required('Campo obrigatório'),
      qty_private_coverages: Yup.number().required('Campo obrigatório'),
    };

    const validationNoCoverage = {
      ...defaultSchema,
      avg_m2_private_coverages: Yup.number().nullable(),
      qty_private_coverages: Yup.number().nullable(),
    };

    return Yup.object().shape(
      togglePrivateCoverages ? defaultSchema : validationNoCoverage,
    );
  }, [togglePrivateCoverages, isConsultancy]);

  const validationCondominium = useMemo(() => {
    const defaultSchema = {
      name: Yup.string().required('Campo obrigatório'),
      cnpj: Yup.string().required('Campo obrigatório'),
      cep: Yup.string().required('Campo obrigatório'),
      uf: Yup.string().required('Campo obrigatório'),
      city: Yup.string().required('Campo obrigatório'),
      address: Yup.string().required('Campo obrigatório'),
    };

    return Yup.object().shape(defaultSchema);
  }, []);

  const validations = [validationUnitInfo, validationCondominium];

  const validationTransmission = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required('Campo obrigatório'),
        email: Yup.string()
          .email('O e-mail deve ser válido')
          .required('Campo obrigatório'),
        phone: Yup.string()
          .required('Campo obrigatório')
          .min(14, 'Verifique o número informado')
          .max(15, 'Verifique o número informado'),
        accept: Yup.boolean()
          .isTrue('Necessário confirmar antes de prosseguir')
          .required('Necessário confirmar antes de prosseguir'),
      }),
    [],
  );

  useEffect(() => {
    if (isConsultancy) {
      api
        .get<Broker[]>('brokers')
        .then((resp) => {
          const { data } = resp;

          setBrokers(
            data.map((brok) => ({
              ...brok,
              name: brok.corporate_reason,
              value: brok.user_id,
            })),
          );
        })
        .finally(() => setLoadingOptions(false));
    } else {
      setLoadingOptions(false);
    }
  }, [user.role, isConsultancy]);

  useEffect(() => {
    if (id) {
      api
        .get<Condominium>(`condominiums/${id}`)
        .then((resp) => {
          const {
            name,
            cnpj,
            uf,
            cep,
            city,
            address,
            unit_info,
            user_id,
            proposals,
          } = resp.data;
          const {
            avg_m2_standard_units,
            qty_standard_units,
            avg_m2_private_coverages,
            qty_private_coverages,
          } = unit_info;

          if (qty_private_coverages) {
            setTogglePrivateCoverages(true);
          }

          if (proposals.length) setProposalId(proposals[0].id);

          setIntialData({
            user_id: user_id.toString(),
            name,
            cnpj: cnpjMask(cnpj),
            uf,
            cep,
            city,
            address,
            avg_m2_standard_units: avg_m2_standard_units.toString(),
            qty_standard_units: qty_standard_units.toString(),
            avg_m2_private_coverages:
              avg_m2_private_coverages?.toString() || '',
            qty_private_coverages: qty_private_coverages?.toString() || '',
          });
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }

    api.get<Options>('condominiums/options').then((resp) => {
      const { coverages: coveragesOptions, multiplier } = resp.data;

      setoptions({
        coverages: coveragesOptions,
        multiplier,
      });
    });
  }, [id]);

  return (
    <>
      <PageHeader
        title="Nova cotação"
        description="Realize a cotação de um condomínio"
        goBackPage
      />
      {loading || loadingOptions ? (
        <Loading />
      ) : (
        <>
          <Formik
            initialValues={initialData}
            validationSchema={validations[currentStep]}
            onSubmit={handleSubmit}
          >
            {({
              isSubmitting,
              errors,
              touched,
              values,
              setValues,
              validateForm,
              setTouched,
            }) => (
              <Form>
                <Flex flexDir={['column', 'row']}>
                  {currentStep < 2 ? (
                    <Box flex={1} mr={[0, 4]} height="full">
                      <Text
                        fontSize="2.75rem"
                        lineHeight="1.2"
                        fontWeight="bold"
                      >
                        Cotação simples e rápida!
                      </Text>
                      <Box mt={6}>
                        <Flex
                          alignItems="center"
                          mb={2}
                          opacity={currentStep === 0 ? 1 : 0.4}
                        >
                          {currentStep === 0 && (
                            <Image
                              src={logoBullet}
                              alt="logo item"
                              height={8}
                              mr={2}
                            />
                          )}

                          <Text
                            ml={currentStep === 0 ? 0 : '43px'}
                            fontSize="28px"
                            fontWeight="bold"
                          >
                            Informações das unidades
                          </Text>
                        </Flex>
                        <Flex
                          alignItems="center"
                          opacity={currentStep === 1 ? 1 : 0.4}
                        >
                          {currentStep === 1 && (
                            <Image
                              src={logoBullet}
                              alt="logo item"
                              height={8}
                              mr={2}
                            />
                          )}
                          <Text
                            ml={currentStep === 1 ? 0 : '43px'}
                            fontSize="28px"
                            fontWeight="bold"
                          >
                            Dados do condomínio
                          </Text>
                        </Flex>
                      </Box>
                    </Box>
                  ) : (
                    <Box flex={1} mr={[0, 4]} height="full">
                      <Text
                        fontSize="2.75rem"
                        lineHeight="1.2"
                        fontWeight="bold"
                      >
                        Seguro Residencial
                      </Text>
                      <Divider my={4} />
                      <Box fontSize="20px">
                        <Box mb={6}>
                          <Text>{`${qtyStandardUnits} unidades padrão`}</Text>
                          {qtyCoverageUnits > 0 && (
                            <Text>{`${qtyCoverageUnits} unidades com cobertura`}</Text>
                          )}
                        </Box>
                        <Box mb={6}>
                          <Text>{`Valor mensal da unidade padrão: R$ ${formatNumber(coverageUnitMonthlyValues.standardUnitValue)}`}</Text>
                          {qtyCoverageUnits > 0 && (
                            <Text>{`Valor mensal da unidade com cobertura: R$ ${formatNumber(coverageUnitMonthlyValues.privateCoverageUnitValue)}`}</Text>
                          )}
                          <Text fontWeight="bold" mt={2}>
                            {`Valor mensal total: R$ ${formatNumber(coverageUnitMonthlyValues.total)}`}
                          </Text>
                        </Box>
                        <Text fontWeight="bold">{`Comissão mensal (20%): R$ ${formatNumber(coverageUnitMonthlyValues.comission)}`}</Text>
                        <Flex flexDir="row-reverse" mt={4}>
                          <Button
                            width="130px"
                            colorScheme="blue"
                            ml={2}
                            // type="submit"
                            onClick={onOpen}
                            // disabled
                            // isLoading={isSubmitting}
                            // isDisabled
                          >
                            Transmitir
                          </Button>
                          {currentStep === 2 && !!coverages.length && (
                            <>
                              {/*  <ProposalReport
                                condominiumData={values}
                                coverages={coverages}
                                coverageUnitMonthlyValue={
                                  coverageUnitMonthlyValues
                                }
                                brokerInfo={{
                                  name: handleGetInfoProposal(values.user_id),
                                }}
                                proposalId={proposalId || 0}
                                saveBlob={(file) => setProposalFileBlob(file)}
                              /> */}
                              <OptimizedProposalReport
                                condominiumData={values}
                                coverages={coverages}
                                coverageUnitMonthlyValue={
                                  coverageUnitMonthlyValues
                                }
                                saveBlob={(file) => setProposalFileBlob(file)}
                              />
                            </>
                          )}
                        </Flex>
                      </Box>
                    </Box>
                  )}

                  <Box
                    flex={2}
                    height="full"
                    maxWidth="700px"
                    p={4}
                    border="1px solid rgba(0,0,0,0.25)"
                    borderRadius="10px"
                  >
                    <Flex alignItems="center" mb={4}>
                      <Image
                        src={logoBullet}
                        alt="logo item"
                        height={8}
                        mr={2}
                      />
                      <Text fontSize="28px" fontWeight="bold">
                        {currentStep === 0 && 'Informações das unidades'}
                        {currentStep === 1 && 'Dados do Condomínio'}
                        {currentStep === 2 && 'Coberturas'}
                      </Text>
                    </Flex>
                    <VStack alignItems="start" spacing={4}>
                      {currentStep === 0 && (
                        <>
                          {/* A validação não ocorrera com o formik */}
                          {isConsultancy && !!brokers.length ? (
                            <Box mb={4} minW="250px">
                              <Select
                                name="user_id"
                                label="Corretora"
                                options={brokers}
                                error={errors.user_id}
                                touched={touched.user_id}
                              />
                            </Box>
                          ) : (
                            <Input
                              type="hidden"
                              name="user_id"
                              value={user.id}
                            />
                          )}
                          <FormControl
                            mt={2}
                            display="flex"
                            alignItems="center"
                          >
                            <FormLabel htmlFor="togglePrivateCoverages" mb="0">
                              Possui cobertura privativa?
                            </FormLabel>
                            <Switch
                              id="togglePrivateCoverages"
                              onChange={() =>
                                setTogglePrivateCoverages(
                                  !togglePrivateCoverages,
                                )
                              }
                              defaultChecked={togglePrivateCoverages}
                            />
                          </FormControl>

                          {togglePrivateCoverages && (
                            <>
                              <Input
                                label="Quantidade de unidades com cobertura privativa"
                                name="qty_private_coverages"
                                error={errors.qty_private_coverages}
                                touched={touched.qty_private_coverages}
                                autoFocus={
                                  !condominiumId && togglePrivateCoverages
                                }
                                type="number"
                              />
                              <Input
                                label="m² médio das unidades com cobetura privativa"
                                name="avg_m2_private_coverages"
                                error={errors.avg_m2_private_coverages}
                                touched={touched.avg_m2_private_coverages}
                                type="number"
                                // mask="decimal"
                              />
                            </>
                          )}
                          <Input
                            label="Quantidade de unidades padrão"
                            name="qty_standard_units"
                            error={errors.qty_standard_units}
                            touched={touched.qty_standard_units}
                            autoFocus={
                              !condominiumId && !togglePrivateCoverages
                            }
                            type="number"
                          />
                          <Input
                            label="m² médio das unidades padrão"
                            name="avg_m2_standard_units"
                            error={errors.avg_m2_standard_units}
                            touched={touched.avg_m2_standard_units}
                            type="number"
                            // mask="decimal"
                          />
                        </>
                      )}
                      {currentStep === 1 && (
                        <>
                          <Input
                            label="Nome do condomínio"
                            name="name"
                            placeholder="Nome do condomínio"
                            error={errors.name}
                            touched={touched.name}
                          />
                          <Input
                            label="CNPJ"
                            name="cnpj"
                            placeholder="00.000.000/0001-00"
                            error={errors.cnpj}
                            touched={touched.cnpj}
                            mask="cnpj"
                          />
                          <Input
                            name="cep"
                            label="CEP"
                            placeholder="00000-000"
                            mask="cep"
                            error={errors.cep}
                            touched={touched.cep}
                            onBlurCustom={async () => {
                              const addressInfo = await getAddress(values.cep);

                              if (!addressInfo) {
                                return;
                              }

                              setValues({
                                ...values,
                                city: addressInfo.city,
                                uf: addressInfo.uf,
                                address: addressInfo.address,
                              });
                            }}
                          />
                          <Select
                            name="uf"
                            label="Estado"
                            options={states}
                            error={errors.uf}
                            touched={touched.uf}
                          />
                          <Input
                            name="city"
                            label="Cidade"
                            placeholder="Cidade"
                            error={errors.city}
                            touched={touched.city}
                          />
                          <Input
                            name="address"
                            label="Endereço"
                            placeholder="Endereço"
                            error={errors.address}
                            touched={touched.address}
                          />
                        </>
                      )}
                      {currentStep === 2 && (
                        <Box>
                          <Table
                            theadData={[
                              { title: 'Coberturas' },
                              { title: 'LMI Unidade Padão' },
                              { title: 'LMI Unidade Cobertura' },
                              { title: 'POS' },
                            ]}
                          >
                            {!!coverages?.length &&
                              coverages.map((cov) => (
                                // console.log(lmiStandard);
                                <Box as="tr" key={cov.id}>
                                  <Box as="td" p={1}>
                                    <Text>{cov.name}</Text>
                                  </Box>
                                  <Box as="td" p={1} textAlign="center">
                                    <Text>{cov.lmiStandardUnit}</Text>
                                  </Box>
                                  <Box as="td" p={1} textAlign="center">
                                    <Text>{cov.lmiPrivateCoverageUnit}</Text>
                                  </Box>
                                  <Box as="td" p={1}>
                                    <Text>{cov.pos ?? 'Não há'}</Text>
                                  </Box>
                                </Box>
                              ))}
                          </Table>
                          <Flex alignItems="center" mt={4} mb={4}>
                            <Image
                              src={logoBullet}
                              alt="logo item"
                              height={8}
                              mr={2}
                            />
                            <Text fontSize="28px" fontWeight="bold">
                              Assistências
                            </Text>
                          </Flex>

                          <Flex justifyContent="space-between">
                            {assistances.map((assistance, i) => (
                              <List spacing={3} pl={4} key={`assist-${i + 1}`}>
                                {assistance.map((assist) => (
                                  <ListItem key={assist}>
                                    <ListIcon
                                      as={FaCheckCircle}
                                      color="green.500"
                                    />
                                    {assist}
                                  </ListItem>
                                ))}
                              </List>
                            ))}
                          </Flex>
                        </Box>
                      )}
                    </VStack>
                    <Flex
                      flexDir={currentStep < 2 ? 'row-reverse' : 'row'}
                      justifyContent="space-between"
                      mt={6}
                    >
                      {currentStep === 0 && (
                        <Button
                          colorScheme="green"
                          onClick={async () => {
                            const err = await validateForm();
                            if (Object.keys(err).length > 0) {
                              setTouched(setNestedObjectValues(err, true));

                              return;
                            }

                            setQtyStandardUnits(
                              Number(values.qty_standard_units),
                            );

                            if (togglePrivateCoverages) {
                              setQtyCoverageUnits(
                                Number(values.qty_private_coverages),
                              );
                            }

                            setCurrentStep(currentStep + 1);
                          }}
                          type="button"
                        >
                          <Flex alignItems="center">
                            <Text mr={2} fontWeight="bold">
                              Avançar
                            </Text>
                            <FaChevronCircleRight size="24px" />
                          </Flex>
                        </Button>
                      )}
                      {currentStep === 1 && (
                        <Button
                          colorScheme="green"
                          type="submit"
                          isLoading={isSubmitting}
                        >
                          <Flex alignItems="center">
                            <Text mr={2} fontWeight="bold">
                              Salvar e Calcular
                            </Text>
                            <FaChevronCircleRight size="24px" />
                          </Flex>
                        </Button>
                      )}

                      {currentStep > 0 && (
                        <Button
                          colorScheme="gray"
                          variant="ghost"
                          onClick={() => {
                            setCurrentStep(currentStep - 1);
                          }}
                          type="button"
                        >
                          <Flex alignItems="center">
                            <FaChevronCircleLeft size="24px" />
                            <Text ml={2} fontWeight="bold">
                              Voltar
                            </Text>
                            {/* <IconButton
                      isRound
                      variant="solid"
                      // colorScheme="teal"
                      aria-label="Avançar"
                      fontSize="20px"
                      icon={<FaChevronRight />}
                    /> */}
                          </Flex>
                        </Button>
                      )}
                    </Flex>
                  </Box>
                </Flex>
              </Form>
            )}
          </Formik>
          <Modal
            title="Transmissão"
            modalFooter={false}
            titleButtonConfirm="Contratar"
            isOpen={isOpen}
            onClose={onClose}
            // isLoading={false}
          >
            <Formik
              initialValues={initialDataTransmission}
              onSubmit={handleSubmitTransmission}
              validationSchema={validationTransmission}
            >
              {({ touched, errors }) => (
                <Form>
                  <VStack spacing={4}>
                    <Input
                      label="Nome do síndico"
                      name="name"
                      placeholder="Nome do síndico"
                      error={errors.name}
                      touched={touched.name}
                    />
                    <Input
                      label="E-mail"
                      name="email"
                      placeholder="E-mail"
                      error={errors.email}
                      touched={touched.email}
                    />
                    <Input
                      label="Telefone"
                      name="phone"
                      placeholder="Telefone"
                      error={errors.phone}
                      touched={touched.phone}
                      mask="phone"
                    />

                    <Field name="accept">
                      {({
                        field,
                        form,
                      }: {
                        field: FieldInputProps<string>;
                        form: FormikProps<FormData>;
                      }) => (
                        <FormControl
                          isInvalid={!!errors.accept && touched.accept}
                        >
                          <Checkbox
                            {...field}
                            isInvalid={!!errors.accept && touched.accept}
                          >
                            Revisei a proposta de seguro residencial e aceito os
                            termos ofertados
                          </Checkbox>

                          {!!errors.accept && touched.accept && (
                            <FormErrorMessage>{errors.accept}</FormErrorMessage>
                          )}
                        </FormControl>
                      )}
                    </Field>
                    <Flex mb={4}>
                      <Button
                        type="button"
                        variant="ghost"
                        mr={2}
                        onClick={onClose}
                      >
                        Cancelar
                      </Button>
                      <Button
                        type="submit"
                        colorScheme="blue"
                        isLoading={loadingTransmission}
                      >
                        Contratar
                      </Button>
                    </Flex>
                  </VStack>
                </Form>
              )}
            </Formik>
          </Modal>
        </>
      )}
    </>
  );
};

export default CalculateQuote;
