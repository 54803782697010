import { User } from 'hooks/useAuth';

function getLocalRefreshToken() {
  // Se utilizar um objeto futuramente deve ser fazer dessa forma, sendo que o refreshTokenData
  // é uma const do token do localstorage
  // return JSON.parse(refreshTokenData);

  return localStorage.getItem('@Benvir:refresh_token');
}

function getLocalAccessToken() {
  return localStorage.getItem('@Benvir:access_token');
}

function updateLocalAccessToken(token: string) {
  localStorage.setItem('@Benvir:access_token', token);
}

function getUser() {
  const userData = localStorage.getItem('@Benvir:user');

  if (!userData) return null;

  return JSON.parse(userData);
}

function setUser(user: User) {
  localStorage.setItem('@Benvir:user', JSON.stringify(user));
}

function removeUser() {
  localStorage.removeItem('@Benvir:user');
}

export {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  removeUser,
};
