import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, background, layout } from '@chakra-ui/react';
import allSegLogo from 'assets/allseg.png';
import badgesInfoCondominium from 'assets/badges-info-condominium-2.png';
import benvirCover from 'assets/benvir-cover-proposal-report-2.png';
import benvirLogoDocument from 'assets/benvir-logo-document.png';
import benvirPrintBlue from 'assets/benvir-print-blue-header.png';
import benvirPrintOrange from 'assets/benvir-print-orange-header.png';
import benvirPrintPurple from 'assets/benvir-print-purple-header.png';
import benvirPrintYellow from 'assets/benvir-print-yellow-header.png';
import pdfFonts from 'assets/fonts/vfs_fonts';
import accomodation from 'assets/icons/assists/accomodation.png';
import airConditioning from 'assets/icons/assists/air-conditioning.png';
import airplane from 'assets/icons/assists/airplane.png';
import ambulance from 'assets/icons/assists/ambulance.png';
import bucket from 'assets/icons/assists/bucket.png';
import carRecovery from 'assets/icons/assists/car-recovery.png';
import childCare from 'assets/icons/assists/child-care.png';
import cleaning from 'assets/icons/assists/cleaning.png';
import domesticServices from 'assets/icons/assists/domestic-services.png';
import eletrician from 'assets/icons/assists/eletrician.png';
import glazier from 'assets/icons/assists/glazier.png';
import key from 'assets/icons/assists/key.png';
import lightning from 'assets/icons/assists/lightning.png';
import microwave from 'assets/icons/assists/microwave.png';
import petFuneral from 'assets/icons/assists/pet-funeral.png';
import petPortion from 'assets/icons/assists/pet-portion.png';
import petProtection from 'assets/icons/assists/pet-protection.png';
import petResidence from 'assets/icons/assists/pet-residence.png';
import phone from 'assets/icons/assists/phone.png';
import plumber from 'assets/icons/assists/plumber.png';
import security from 'assets/icons/assists/security.png';
import truck from 'assets/icons/assists/truck.png';
import unblocker from 'assets/icons/assists/unblocker.png';
import breakingGlass from 'assets/icons/coverages/breaking-glass.png';
import coin from 'assets/icons/coverages/coin.png';
import fire from 'assets/icons/coverages/fire.png';
import lock from 'assets/icons/coverages/lock.png';
import plug from 'assets/icons/coverages/plug.png';
import users from 'assets/icons/coverages/users.png';
import starBullet from 'assets/icons/institutional/star.png';
import imagotype from 'assets/imagotipo.png';
import lineThead from 'assets/line-thead.png';
import rectangle from 'assets/rectangle-2.png';
import { format } from 'date-fns';
import {
  CoverageCalc,
  CoverageUnitMonthlyValues,
  FormData,
} from 'modules/quotes/pages/CalculateQuote';
import pdfMake from 'pdfmake/build/pdfmake';
import { PatternFill, TDocumentDefinitions } from 'pdfmake/interfaces';
import { text } from 'stream/consumers';
import { formatNumber } from 'utils/formatNumber';
import { loadImageAsDataURL } from 'utils/loadImageAsDataUrl';
import { cepMask } from 'utils/masks';

interface ProposalReportProps {
  condominiumData: FormData;
  coverages: CoverageCalc[];
  coverageUnitMonthlyValue: CoverageUnitMonthlyValues;
  saveBlob?(file: Blob): void;
}

const OptimizedProposalReport: React.FC<ProposalReportProps> = ({
  condominiumData,
  coverages,
  coverageUnitMonthlyValue,
  saveBlob,
  /* No momento utilizando apenas o nome da corretora,
  verificar se é necessário incluir o cnpj dele ou do condomínio, como está */
}) => {
  const [pdfFile, setPdfFile] = useState<pdfMake.TCreatedPdf>();
  const pdfDoneRef = useRef(false);

  // const handleResources = useCallback(async () => {}, []);

  useEffect(() => {
    // Resetar o estado global de pdfMake
    /* pdfMake.vfs = {};
    pdfMake.fonts = {}; */

    if (pdfDoneRef.current) {
      return;
    }

    pdfDoneRef.current = false;

    const loadImages = async () => {
      // console.log('teste loadImages');
      const benvirCoverUrl = await loadImageAsDataURL(benvirCover);
      const imagotypeUrl = await loadImageAsDataURL(imagotype);
      const benvirPrintBlueUrl = await loadImageAsDataURL(benvirPrintBlue);
      const benvirPrintYellowUrl = await loadImageAsDataURL(benvirPrintYellow);
      const benvirPrintOrangeUrl = await loadImageAsDataURL(benvirPrintOrange);
      const benvirPrintPurpleUrl = await loadImageAsDataURL(benvirPrintPurple);
      const benvirLogoDocumentUrl =
        await loadImageAsDataURL(benvirLogoDocument);
      const badgesInfoCondominiumUrl = await loadImageAsDataURL(
        badgesInfoCondominium,
      );
      const allSegLogoUrl = await loadImageAsDataURL(allSegLogo);
      const rectangleUrl = await loadImageAsDataURL(rectangle);
      const starBulletUrl = await loadImageAsDataURL(starBullet);
      const keyUrl = await loadImageAsDataURL(key);
      const lightningUrl = await loadImageAsDataURL(lightning);
      const plumberUrl = await loadImageAsDataURL(plumber);
      const unblockerUrl = await loadImageAsDataURL(unblocker);
      const microwaveUrl = await loadImageAsDataURL(microwave);
      const airConditioningUrl = await loadImageAsDataURL(airConditioning);
      const bucketUrl = await loadImageAsDataURL(bucket);
      /* Adicionar ao objeto images */
      const phoneUrl = await loadImageAsDataURL(phone);
      const eletricianUrl = await loadImageAsDataURL(eletrician);
      const petFuneralUrl = await loadImageAsDataURL(petFuneral);
      const petProtectionUrl = await loadImageAsDataURL(petProtection);
      const glazierUrl = await loadImageAsDataURL(glazier);
      const accomodationUrl = await loadImageAsDataURL(accomodation);
      const cleaningUrl = await loadImageAsDataURL(cleaning);
      const securityUrl = await loadImageAsDataURL(security);
      const truckUrl = await loadImageAsDataURL(truck);
      const petResidenceUrl = await loadImageAsDataURL(petResidence);
      const petPortionUrl = await loadImageAsDataURL(petPortion);
      const ambulanceUrl = await loadImageAsDataURL(ambulance);
      const airplaneUrl = await loadImageAsDataURL(airplane);
      const carRecoveryUrl = await loadImageAsDataURL(carRecovery);
      const domesticServicesUrl = await loadImageAsDataURL(domesticServices);
      const childCareUrl = await loadImageAsDataURL(childCare);
      const fireUrl = await loadImageAsDataURL(fire);
      const plugUrl = await loadImageAsDataURL(plug);
      const breakingGlassUrl = await loadImageAsDataURL(breakingGlass);
      const coinUrl = await loadImageAsDataURL(coin);
      const lockUrl = await loadImageAsDataURL(lock);
      const usersUrl = await loadImageAsDataURL(users);
      const lineTheadUrl = await loadImageAsDataURL(lineThead);

      const doc: TDocumentDefinitions = {
        pageSize: 'A4',
        pageMargins: [39, 126, 39, 58],
        info: {
          title: 'Estudo Benvir',
        },
        header: (currentPage: number, pageCount: number, pageSize: any) => {
          switch (currentPage) {
            /* case 1:

              }; */
            case 2:
            case 3:
              return [
                {
                  image: 'printBlue',
                  // opacity: 0.35,
                  height: 126,
                  width: 602,
                  // margin: [0, 126, 0, 0],
                },
              ];
            case 4:
            case 5:
            case 6:
              return [
                {
                  image: 'printYellow',
                  // opacity: 0.35,
                  height: 126,
                  width: 602,
                  // absolutePosition: { x: 0, y: 0 }, // posição absoluta no topo da página
                },
              ];
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
              return [
                {
                  image: 'printOrange',
                  // opacity: 0.35,
                  height: 126,
                  width: 602,
                  // absolutePosition: { x: 0, y: 0 }, // posição absoluta no topo da página
                },
              ];
            case 15:
            case 16:
            case 17:
              return [
                {
                  image: 'printPurple',
                  // opacity: 0.35,
                  height: 126,
                  width: 602,
                  // absolutePosition: { x: 0, y: 0 }, // posição absoluta no topo da página
                },
              ];
            default:
              return '';
          }
        },
        footer: (currentPage: number, pageCound: number, pageSize: any) => [
          {
            layout: 'noBorders',
            table: {
              widths: ['auto', '*', 'auto'],
              body: [
                [
                  {
                    text: `Data: ${format(new Date(), 'dd/MM/yyyy')}`,
                    noWrap: true,
                    fontSize: 12,
                  },
                  {
                    text: 'Produto garantido pela Allseg I Código SUSEP: 581-9',
                    alignment: 'center',
                    fontSize: 12,
                  },

                  {
                    stack: [
                      {
                        text: currentPage,
                        alignment: 'right',
                        fontSize: 10,
                      },
                      {
                        image: 'logo',
                        height: 52,
                        width: 58,
                        absolutePosition: {
                          x: currentPage > 9 ? 522.5 : 524.5,
                          y: -18,
                        },
                        // absolutePosition: { x: 0, y: 0 },
                      },
                    ],
                  },

                  // currentPage,
                ],
              ],
            },
            margin: [39, 0, 39, 39],
          },
        ],
        content: [
          {
            stack: [
              // Capa
              {
                image: 'cover',
                height: 219,
                width: 600,
                absolutePosition: { x: 0, y: 0 },
              },
              {
                text: 'Estudo Personalizado',
                fontSize: 18,
                absolutePosition: { x: 39, y: 122 },
              },
              {
                layout: 'noBorders',
                table: {
                  widths: [312],
                  body: [
                    [
                      {
                        text: condominiumData.name.toUpperCase(),
                        fontSize: 22,
                        bold: true,
                        // absolutePosition: { x: 39, y: 188 },
                      },
                    ],
                  ],
                },
                absolutePosition: { x: 39, y: 144 },
                // margin: [0, 30, 0, 0],
              },
              {
                margin: [0, 133, 0, 0],
                columns: [
                  {
                    text: 'Neste estudo, você encontrará informações importantes sobre a Benvir, as coberturas e assistências que estamos oferecendo e o seu respectivo preço.\n\nAs coberturas foram sugeridas considerando o custo unitário básico da construção civil (CUB). \n\nCaso precise de esclarecimentos, conte comigo!',
                    width: 250,
                  },
                  {
                    stack: [
                      {
                        absolutePosition: { x: 351, y: 259 },
                        image: 'badgesInfo',
                        height: 157,
                        width: 191,
                      },
                      {
                        text: cepMask(condominiumData.cep),
                        absolutePosition: { x: 380, y: 264 },
                        alignment: 'center',
                        fontSize: 12,
                      },
                      {
                        text: condominiumData.qty_standard_units,
                        absolutePosition: { x: 480, y: 297 },
                        alignment: 'center',
                        fontSize: 12,
                      },
                      {
                        text: condominiumData.avg_m2_standard_units,
                        absolutePosition: { x: 330, y: 330 },
                        alignment: 'center',
                        fontSize: 12,
                      },
                      {
                        text: condominiumData?.qty_private_coverages || 0,
                        absolutePosition: { x: 488, y: 362 },
                        alignment: 'center',
                        fontSize: 12,
                      },
                      {
                        text: condominiumData?.avg_m2_private_coverages || 0,
                        absolutePosition: { x: 330, y: 395 },
                        alignment: 'center',
                        fontSize: 12,
                      },
                    ],
                  },
                ],
              },
              {
                margin: [0, 45, 0, 0],
                text: 'PARCER CORRETORES DE SEGUROS PARCEIROS LTDA \nSusep: 20228389 \nTelefone: (61) 99983-8582 \natendimento@parcerseguros.com.br',
              },
            ],
          },
          {
            margin: [0, 28, 0, 0],
            stack: [
              {
                image: 'rectangle',
                width: 549,
                absolutePosition: { x: 23, y: 154 },
              },
              {
                margin: [51, 16, 0, 0],
                text: 'Quem é a Benvir?',
                bold: true,
                fontSize: 20,
              },
              {
                margin: [61, 16, 41, 0],
                text: 'Somos uma assessoria de seguros, e o nosso objetivo é levar tranquilidade e proteção aos síndicos e moradores de condomínios prediais com um seguro descomplicado para as unidades. Com toda expertise de mercado e com ajuda da tecnologia, conseguimos entregar com apenas alguns cliques um seguro residencial com excelente custo e benefícios para todos os condôminos.',
                fontSize: 13,
                // lineHeight: 1.4,
              },
              {
                margin: [61, 60, 0, 0],
                text: 'Qual é a seguradora\nparceira da Benvir?',
                bold: true,
                fontSize: 20,
              },
              {
                margin: [61, 20, 51, 0],
                text: 'Nossa seguradora parceira é a Allseg. Com mais de 50 anos de mercado, a Allseg construiu uma história de muito Sucesso. Em 2022 foram 41 milhões em receitas e mais de 1,5 milhões de clientes protegidos',
                fontSize: 13,
              },
              {
                margin: [53, 16, 0, 0],
                image: 'allSegLogo',
                width: 136,
              },
            ],
            pageBreak: 'before',
          },
          [
            {
              margin: [23, 34, 0, 0],
              text: 'Por que ter um\nseguro Benvir?',
              bold: true,
              fontSize: 20,
              pageBreak: 'before',
            },
            {
              margin: [23, 28, 16, 0],
              stack: [
                {
                  columns: [
                    {
                      image: 'starBullet',
                      width: 22,
                    },
                    {
                      marginTop: 2,
                      marginLeft: 16,
                      text: 'Planos adequados as necessidades dos condôminos.',
                      fontSize: 13,
                    },
                  ],
                },
                {
                  margin: [0, 22, 16, 0],
                  columns: [
                    {
                      image: 'starBullet',
                      width: 22,
                    },
                    {
                      marginTop: 2,
                      marginLeft: 16,
                      text: 'Agilidade na cotação e contratação.',
                      fontSize: 13,
                    },
                  ],
                },
                {
                  margin: [0, 22, 16, 0],
                  columns: [
                    {
                      image: 'starBullet',
                      width: 22,
                    },
                    {
                      marginTop: 2,
                      marginLeft: 16,
                      text: 'Pagamento mensal e unificado.',
                      fontSize: 13,
                    },
                  ],
                },
                {
                  margin: [0, 22, 16, 0],
                  columns: [
                    {
                      image: 'starBullet',
                      width: 22,
                    },
                    {
                      marginTop: 2,
                      marginLeft: 16,
                      text: 'Sem carência ou multas.',
                      fontSize: 13,
                    },
                  ],
                },
                {
                  margin: [0, 22, 16, 0],
                  columns: [
                    {
                      image: 'starBullet',
                      width: 22,
                    },
                    {
                      marginTop: 2,
                      marginLeft: 16,
                      text: 'Contratou, usou.',
                      fontSize: 13,
                    },
                  ],
                },
              ],
            },
          ],
          {
            stack: [
              {
                layout: 'lightHorizontalLines',
                table: {
                  widths: ['*', '*', '*'],
                  heights: 60,
                  body: [
                    [
                      { text: 'Coberturas', style: 'tableHeaderCoverages' },
                      {
                        text: 'LMI Unidade\nPadrão',
                        style: 'tableHeaderCoverages',
                      },
                      {
                        text: 'LMI Unidade c/\nCobertura Privativa',
                        style: 'tableHeaderCoverages',
                      },
                    ],
                    ...coverages.map((cov) => [
                      { text: cov.name, fontSize: 14, margin: [0, 15, 0, 0] },
                      {
                        text: `R$ ${cov.lmiStandardUnit}`,
                        fontSize: 14,
                        margin: [0, 15, 0, 0],
                        bold: true,
                      },
                      {
                        text:
                          cov.lmiPrivateCoverageUnit !== '-'
                            ? `R$ ${cov.lmiPrivateCoverageUnit}`
                            : '-',
                        fontSize: 14,
                        margin: [0, 15, 0, 0],
                        bold: true,
                      },
                    ]),
                  ],
                },
              },
              {
                marginTop: 12,
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Participação obrigatória do segurado (POS): Danos elétricos 10% com mínimo de R$ 480; Roubo ou furto 10% com mínimo de R$ 100; Quebra de vidros 10% com mínimo de R$ 150.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
            ],
            margin: [0, 8, 0, 8],
            pageBreak: 'before',
          },
          {
            marginTop: 34,
            stack: [
              {
                text: 'Assistências',
                fontSize: 12,
                bold: true,
                color: '#A6A6A6',
                marginBottom: 4,
              },
              {
                image: 'lineThead',
                width: 530,
                height: 2,
              },
              /* {
                canvas: [
                  {
                    type: 'rect',
                    x: 0,
                    y: 0,
                    w: 500,
                    h: 2,
                    color: '#D9D9D9',
                  },
                ],
                absolutePosition: { x: 39, y: 160 },
              }, */
              {
                marginTop: 12,
                marginLeft: 14,
                layout: 'noBorders',
                table: {
                  widths: ['*', '*'],
                  heights: 40,
                  body: [
                    [
                      { text: 'Chaveiro', bold: true, fontSize: 12 },
                      { text: 'Hospedagem', bold: true, fontSize: 12 },
                    ],
                    [
                      { text: 'Eletricista', bold: true, fontSize: 12 },
                      {
                        text: 'Limpeza da residência',
                        bold: true,
                        fontSize: 12,
                      },
                    ],
                    [
                      { text: 'Encanador', bold: true, fontSize: 12 },
                      {
                        text: 'Segurança e vigilância',
                        bold: true,
                        fontSize: 12,
                      },
                    ],
                    [
                      {
                        text: 'Desentupimento de tubulação',
                        bold: true,
                        fontSize: 12,
                      },
                      {
                        text: 'Mudança e guarda de móveis',
                        bold: true,
                        fontSize: 12,
                      },
                    ],
                    [
                      {
                        text: 'Conserto de eletrodoméstico',
                        bold: true,
                        fontSize: 12,
                      },
                      { text: 'Pet - Hospedagem', bold: true, fontSize: 12 },
                    ],
                    [
                      {
                        text: 'Conserto de ar concidicionado',
                        bold: true,
                        fontSize: 12,
                      },
                      { text: 'Pet - Ração', bold: true, fontSize: 12 },
                    ],
                    [
                      { text: 'Caçamba', bold: true, fontSize: 12 },
                      { text: 'Pet - Transporte', bold: true, fontSize: 12 },
                    ],
                    [
                      {
                        text: 'Conserto de aparelho telefônico',
                        bold: true,
                        fontSize: 12,
                      },
                      { text: 'Regresso antecipado', bold: true, fontSize: 12 },
                    ],
                    [
                      {
                        text: 'Indicação de profissionais',
                        bold: true,
                        fontSize: 12,
                      },
                      {
                        text: 'Recuperação de veículo',
                        bold: true,
                        fontSize: 12,
                      },
                    ],
                    [
                      {
                        text: 'Pet assistência funeral',
                        bold: true,
                        fontSize: 12,
                      },
                      {
                        text: 'Serv. domésticos provisórios',
                        bold: true,
                        fontSize: 12,
                      },
                    ],
                    [
                      { text: 'Pet Protection', bold: true, fontSize: 12 },
                      { text: 'Cuidado de crianças', bold: true, fontSize: 12 },
                    ],
                    [
                      { text: 'Vidraceiro', bold: true, fontSize: 12 },
                      {
                        text: 'Remoção inter hospitalar',
                        bold: true,
                        fontSize: 12,
                      },
                    ],
                  ],
                },
              },
            ],
            pageBreak: 'before',
          },
          {
            // marginTop: 28,
            stack: [
              {
                layout: 'lightHorizontalLines',
                table: {
                  widths: ['*', '*', '*'],
                  heights: 60,
                  body: [
                    [
                      {
                        text: '',
                        style: 'tableHeaderCoverages',
                      },
                      {
                        text: 'LMI Unidade\nPadrão',
                        style: 'tableHeaderCoverages',
                      },
                      {
                        text: 'LMI Unidade c/\nCobertura Privativa',
                        style: 'tableHeaderCoverages',
                      },
                    ],
                    [
                      {
                        text: 'Valor mensal por unidade',
                        marginTop: 15,
                      },
                      {
                        text: `R$ ${formatNumber(coverageUnitMonthlyValue.standardUnitValue)}`,
                        bold: true,
                        marginTop: 15,
                      },
                      {
                        text: coverageUnitMonthlyValue?.privateCoverageUnitValue
                          ? `R$ ${formatNumber(coverageUnitMonthlyValue.privateCoverageUnitValue)}`
                          : '',
                        bold: true,
                        marginTop: 15,
                      },
                    ],
                  ],
                },
              },
              {
                marginTop: 12,
                layout: 'noBorders',
                table: {
                  widths: ['*', '*'],
                  body: [
                    [
                      {
                        text: [
                          {
                            text: 'Valor total mensal\n',
                            bold: true,
                            fontSize: 15,
                          },
                          { text: 'Todas unidades' },
                        ],
                        style: 'textBox',
                      },
                      {
                        text: [
                          {
                            text: `R$ ${formatNumber(coverageUnitMonthlyValue.total)}\n`,
                            bold: true,
                            fontSize: 15,
                            alignment: 'right',
                          },
                          { text: 'Boleto mensal', alignment: 'right' },
                        ],
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
            ],
            pageBreak: 'before',
          },
          /* Pág. 7 */
          {
            marginTop: 34,
            stack: [
              {
                text: 'Serviços em Decorrência de\nProblemas emergenciais',
                style: 'title',
              },
              {
                columns: [
                  {
                    image: 'key',
                    width: 17,
                  },
                  {
                    text: 'Chaveiro',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Caso o Segurado necessite entrar na Residência Segurada e tenha perdido ou quebrado a chave dentro da fechadura da porta principal de acesso ao imóvel, ou ainda em caso de roubo ou furto da mesma, a Seguradora se encarregará do envio de um chaveiro para a realização dos reparos/abertura da porta e confecção de nova chave, se necessário. ESTÃO EXCLUÍDAS DESTE SERVIÇO AS FECHADURAS DE PORTAS INTERNAS, GUARDA-ROUPAS, JANELAS INTERNAS DA RESIDÊNCIA SEGURADA, ASSIM COMO PEÇAS QUE EVENTUALMENTE TENHAM QUE SER SUBSTITUÍDAS.\nLimites: Abertura de porta: R$ 200,00/utilização; 02 utilizações/ano. Reparo de fechaduras: R$ 480,00/utilização; 02 utilizações/ano.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
              {
                marginTop: 12,
                columns: [
                  {
                    image: 'lightning',
                    width: 17,
                  },
                  {
                    text: 'Eletricista',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Em caso de falta de energia elétrica na Residência Segurada devido a uma falha ou avaria nas suas instalações elétricas, a Seguradora se encarregará do envio de um profissional para a reparação emergencial necessária para restabelecer a energia elétrica, sempre que o estado da instalação permitir. Incluem-se neste serviço os custos decorrentes do envio do profissional, materiais (aqueles necessários e indispensáveis à execução do serviço) e mão de obra, SENDO EXCLUÍDA A REPARAÇÃO DE ELEMENTOS PRÓPRIOS DA ILUMINAÇÃO, TAIS COMO LÂMPADAS, LÂMPADAS FLUORESCENTES, INTERRUPTORES, TOMADAS, BOMBAS ELÉTRICAS, ASSIM COMO A REPARAÇÃO DE AVARIAS QUE SOFRAM OS APARELHOS DE CALEFAÇÃO, ELETRODOMÉSTICOS E, EM GERAL, DE QUALQUER AVARIA DE APARELHOS QUE FUNCIONEM POR CORRENTE ELÉTRICA, ALÉM DE PEÇAS QUE EVENTUALMENTE TENHAM QUE SER SUBSTITUÍDAS.\nLimites: 4 intervenções/ano; R$ 100,00 por utilização.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
              {
                marginTop: 12,
                columns: [
                  {
                    image: 'plumber',
                    width: 25,
                  },
                  {
                    text: 'Encanador',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Em caso de vazamento interno que esteja causando ou possa causar alagamento na Residência Segurada, a Seguradora se encarregará do envio de um profissional para sua reparação. Incluem-se neste serviço os custos decorrentes do envio do profissional, materiais (aqueles necessários e indispensáveis à execução do serviço) e mão de obra, SENDO EXCLUÍDAS QUAISQUER PEÇAS QUE EVENTUALMENTE TENHAM QUE SER SUBSTITUÍDAS. A SEGURADORA NÃO ASSUMIRÁ CUSTOS DE REPARO DEFINITIVO, NEM SERVIÇOS DE ALVENARIA OU QUALQUER SERVIÇO DE DESOBSTRUÇÃO.\nLimites: 2 intervenções/ano; R$ 400,00 por utilização.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
            ],
            pageBreak: 'before',
          },
          /* Pág. 8 */
          {
            marginTop: 8,
            stack: [
              {
                columns: [
                  {
                    image: 'unblocker',
                    width: 17,
                    height: 25,
                  },
                  {
                    text: 'Desentupimento de tubulação',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Ocorrendo entupimento de encanamentos e tubulações da Residência Segurada, a Seguradora se encarregará do envio de profissionais para efetuar o serviço de desentupimento, incluídos os serviços relativos a esgotos e caixas de gordura, desde que limitados à área interna do imóvel. Incluem-se neste serviço os custos decorrentes do envio do profissional, materiais (aqueles necessários e indispensáveis à execução do serviço) e mão de obra, SENDO EXCLUÍDAS QUAISQUER PEÇAS QUE EVENTUALMENTE TENHAM QUE SER SUBSTITUÍDAS.\nLimites: 2 intervenções/ano; R$ 200,00.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
              {
                marginTop: 8,
                columns: [
                  {
                    image: 'microwave',
                    width: 25,
                  },
                  {
                    text: 'Conserto de eletrodomésticos',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Em caso de quebra de quaisquer dos eletrodomésticos especificados a seguir que guarnecem a Residência Segurada, a Seguradora se encarregará do envio de profissionais devidamente qualificados e previamente selecionados, para a realização dos consertos que se façam necessários. Os serviços serão prestados em horário comercial (respeitada a disponibilidade dos prestadores) para os seguintes tipos de eletrodomésticos: Fogões; Tanquinho; Refrigeradores; Centrífuga de roupas; Freezers; Depurador de ar; Máquinas de lavar roupa; Exaustor de ar; Máquinas de secar roupa; Frigobar; Lava-louças; Ar condicionado. Forno de micro-ondas; Incluem-se neste serviço os custos decorrentes do envio do profissional, materiais (aqueles necessários e indispensáveis à execução do serviço), mão de obra e peças, observados os limites descritos abaixo: Observações: \na) SOMENTE SERÃO PASSÍVEIS DE REPAROS OS EQUIPAMENTOS COM ATÉ 5 (CINCO) ANOS DE FABRICAÇÃO, COMPROVADOS ATRAVÉS DE NOTA FISCAL OU PELO MODELO DO APARELHO;\nb) ESTÃO EXCLUÍDOS DESTE SERVIÇO OS EQUIPAMENTOS QUE ESTEJAM FORA DE LINHA, ISTO É, PARA OS QUAIS NÃO SEJA POSSÍVEL ENCONTRAR PEÇAS À VENDA NO MERCADO;\nc) NÃO SERÃO FEITAS REVISÃO GERAL E LIMPEZA;\nd) NÃO SÃO COBERTOS DANOS CAUSADOS POR TRANSPORTE INTERNO OU EXTERNO.\nLimites: 2 intervenções/ano; R$ 200,00.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
              {
                marginTop: 8,
                columns: [
                  {
                    image: 'airConditioning',
                    width: 20,
                  },
                  {
                    text: 'Conserto de Ar Condicionado',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Em caso de dano em aparelhos de ar condicionado, a SEGURADORA fornecerá a mão de obra para reparo de aparelho de ar condicionado, em caso de desgaste natural dos componentes elétricos, eletrônicos ou mecânicos no aparelho, observados os limites descritos nestas Condições Específicas. Incluem-se neste serviço os custos decorrentes do envio do profissional e mão de obra, SENDO EXCLUÍDAS QUAISQUER PEÇAS QUE EVENTUALMENTE TENHAM QUE SER SUBSTITUÍDAS.\nLimites: 2 intervenções/ano; R$ 200,00 por intervenção.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
              {
                marginTop: 8,
                columns: [
                  {
                    marginTop: 2,
                    image: 'bucket',
                    width: 25,
                  },
                  {
                    text: 'Caçamba',
                    style: 'topic',
                    marginBottom: 8,
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Quando solicitado pelo Segurado, a SEGURADORA providenciará com fornecimento de 1 (uma) caçamba de 5m3 (cinco metros cúbicos), para que o Segurado possa realizar a retirada do entulho da Residência Segurada, observando-se a legislação vigente. Limites: 1 intervenção/ano.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
            ],
            pageBreak: 'before',
          },
          /* Pág. 9 */
          {
            marginTop: 18,
            stack: [
              {
                columns: [
                  {
                    image: 'phone',
                    width: 25,
                  },
                  {
                    text: 'Conserto de Aparelho Telefônico',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Em caso de dano em aparelhos telefônicos, a assistência fornecerá a mão de obra para reparo de aparelhos telefônicos convencionais (exceto mesas telefônicas, interfones, KS, PABX ou similares), em caso de desgaste natural dos componentes elétricos, eletrônicos ou mecânicos no aparelho. Incluem-se neste serviço os custos decorrentes do envio do profissional e mão de obra, SENDO EXCLUÍDAS QUAISQUER PEÇAS QUE EVENTUALMENTE TENHAM QUE SER SUBSTITUÍDAS.\nLimites: 1 intervenção/ano; R$ 200,00.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
              {
                marginTop: 12,
                columns: [
                  {
                    marginBottom: 2,
                    image: 'eletrician',
                    width: 25,
                  },
                  {
                    text: 'Indicação de profissionais',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Quando solicitado pelo Segurado, para fins de manutenção da Residência Segurada, a Seguradora providenciará a indicação de profissionais para elaboração de orçamento e execução dos serviços. O serviço de indicação de Profissionais consiste no atendimento telefônico pelo qual serão fornecidos os contratos de prestadores do ramo da construção civil, bem como reparos residenciais. Os profissionais que poderão ser indicados serão: Carpinteiro, Limpeza, Eletricista, Chaveiro, Pedreiro, Conserto de eletrodomésticos, Marceneiro, Dedetização, Vidraceiro, Desratização, Encanador, Desentupimento, Pintor. A responsabilidade pela aprovação do orçamento e pagamento integral dos serviços executados é exclusivamente do Segurado, assim como pela aquisição dos materiais e peças necessários à sua execução. Os serviços serão prestados em horário comercial, respeitada a disponibilidade dos prestadores.\nLimites: Sem limite de utilização.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
              {
                marginTop: 12,
                columns: [
                  {
                    image: 'petFuneral',
                    width: 25,
                  },
                  {
                    text: 'Pet Assistência Funeral',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Despesas com a organização do funeral, cremação ou enterro do animal de estimação do usuário. Observação:\n▪ Animal doméstico: cachorro e gato;\n▪ Será fornecido o serviço até o limite abaixo.\n▪ Para o fornecimento da assistência é necessária a presença do usuário ou responsável pelo animal no momento do atendimento;\n▪ Este serviço não cobre os acontecimentos ou consequências resultantes de morte ou sacrifício induzido ou lesão no animal, causados pelo usuário;\n▪ Estão excluídos os serviços solicitados realizados pelo usuário sem prévio consentimento da central de atendimento;\n▪ Horário de atendimento: Horário do estabelecimento.\nLimites: 1 utilização/ano; R$ 400,00.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
            ],
            pageBreak: 'before',
          },
          /* Pág. 10 */
          {
            marginTop: 18,
            stack: [
              {
                columns: [
                  {
                    image: 'petProtection',
                    width: 25,
                  },
                  {
                    text: 'PET PROTECTION',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        stack: [
                          {
                            text: 'Definição: Entende-se por Animal Assistido cães e gatos, indicados legalmente como domésticos, que possuam peso máximo de 80 quilos e, ainda, que convivam com o Segurado em sua residência habitual. Exclusão: animais silvestres, não domesticados e destinados a competições.',
                          },
                          { text: 'Serviços:', bold: true },

                          {
                            ul: [
                              {
                                marginTop: 4,
                                text: 'Assistência Veterinária',
                                decoration: 'underline',
                              },
                            ],
                          },
                          'Em caso de lesão do Animal Assistido, a Seguradora garante, mediante prévio contato com a Central de Atendimento, o reembolso da assistência veterinária necessária, referentes a gastos com cirurgias e medicamentos, observados os limites descritos nestas Condições Específicas e excluídos os atendimentos em decorrência de doenças preexistentes.',
                          {
                            ul: [
                              {
                                marginTop: 4,
                                text: 'Transporte Emergencial',
                                decoration: 'underline',
                              },
                            ],
                          },
                          'Em caso de lesão do Animal Assistido, a Seguradora se responsabilizará pelo seu transporte até a clínica veterinária mais próxima do local do evento, desde que o Segurado não tenha condições físicas comprovadas de fazê-lo por seus próprios meios, sendo o meio de transporte mais adequado definido pela Seguradora. Após alta do Animal Assistido, caso o Segurado não tenha condições comprovadas de buscá-lo na clínica onde se encontra internado, e não haja nenhum outro responsável indicado por ele apto a fazê-lo, a Seguradora se responsabilizará pelo seu transporte pelo meio considerado mais adequado.',
                          {
                            ul: [
                              {
                                marginTop: 4,
                                text: 'Envio de Ração a Domicílio',
                                decoration: 'underline',
                              },
                            ],
                          },
                          'Quando solicitado pelo Segurado, a SEGURADORA se responsabilizará pela aquisição, no estabelecimento mais próximo da residência do mesmo, e envio da ração de tipo e marca de sua escolha para consumo do Animal Assistido até o local de seu domicílio, sendo que o custo da aquisição dos produtos correrá por conta do Segurado.',
                          {
                            ul: [
                              {
                                marginTop: 4,
                                text: 'Implantação de Microchip',
                                decoration: 'underline',
                              },
                            ],
                          },
                          'Quando solicitado pelo Segurado, a SEGURADORA se responsabilizará pelo serviço de implantação de microchip no Animal Assistido, sendo que o custo referente ao chip e manutenção do mesmo permanecerão sob responsabilidade do Segurado.',
                          {
                            ul: [
                              {
                                marginTop: 4,
                                text: 'Hospedagem de Animais',
                                decoration: 'underline',
                              },
                            ],
                          },
                          'Em caso de lesão do Segurado que o impeça de cuidar do Animal Assistido, a SEGURADORA se responsabilizará pelo seu transporte de ida e volta e estadia em hotel para animais ou canil, mais próximo do local do evento, que seja considerado adequado para prestação deste serviço, observados os limites descritos nestas Condições Específicas. Sendo necessário um período superior ao coberto por este serviço complementar, as diárias adicionais serão de responsabilidade do Segurado.',
                          {
                            ul: [
                              {
                                marginTop: 4,
                                text: 'Concierge Veterinário',
                                decoration: 'underline',
                              },
                            ],
                          },
                          'Mediante solicitação do Segurado, a SEGURADORA poderá indicar os seguintes serviços especializados: Indicação de pet shop, clínicas, hotéis, banho e tosa e outros estabelecimentos; Indicação de produtos e vacinas; Eventos veterinários; Informações sobre raças de cães; Indicação de sites para adoção de animais; Orientação e indicação para adestramento do animal.\nPrazo: as informações serão respondidas em até 2 (dois) dias úteis para o Segurado. Limites: Todo o pacote está limitado a 1 utilização por vigência. Assistência Emergencial: 1 utilização/ano; até R$200,00. Transporte emergencial:1 utilização/ano; R$150,00. Concierge veterinário. Implantação de microchip: 1 utilização/ano; até R$100,00. Envio de ração ao domicílio: 1 utilização/ano; até R$150,00. Hospedagem: 1 utilização/ano; até 5 dias; R$ 100,00 por dia.',
                        ],
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
            ],
            pageBreak: 'before',
          },
          /* Pág. 11 */
          {
            marginTop: 34,
            stack: [
              {
                text: 'Serviços em Decorrência de\nSinistro',
                style: 'title',
              },
              {
                columns: [
                  {
                    image: 'glazier',
                    width: 24,
                  },
                  {
                    text: 'Vidraceiro',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Se, em decorrência de sinistro ocorrido na Residência Segurada for necessário o reparo em vidros, a Seguradora se encarregará de enviar profissionais capacitados que realizarão o reparo emergencial dos vidros da Residência Segurada, desde que tecnicamente possível, observados os limites descritos nestas Condições Específicas.\nLimites: 2 intervenções/ano; R$ 200,00 por utilização.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
              {
                marginTop: 12,
                columns: [
                  {
                    image: 'accomodation',
                    width: 20,
                  },
                  {
                    text: 'Hospedagem',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Quando for verificada a impossibilidade de habitação do imóvel em consequência de sinistro coberto pela apólice, será providenciada pela Seguradora a reserva e pagamento de diárias de hotel para acomodação do Segurado e seus beneficiários observados os limites descritos nestas Condições Específicas.\nA ESCOLHA DO HOTEL SERÁ FEITA PELA SEGURADORA, DEVENDO LOCALIZAR-SE O MAIS PRÓXIMO POSSÍVEL DA RESIDÊNCIA SEGURADA. AS DESPESAS DE LOCOMOÇÃO E RESPECTIVO RETORNO CORRERÃO POR CONTA DO SEGURADO E/OU SEUS BENEFICIÁRIOS.\nLimites: R$ 150,00/pessoa/dia; 2 diárias de hotel; até 5 pessoas.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
              {
                marginTop: 12,
                columns: [
                  {
                    image: 'cleaning',
                    width: 25,
                  },
                  {
                    text: 'Limpeza da Residência',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Se, em decorrência de sinistro devidamente coberto pela apólice, houver a necessidade do envio de profissionais de limpeza à Residência Segurada, a SEGURADORA se responsabilizará pelas despesas daí advindas, observados os limites nestas Condições Específicas.\nLimites: 1 intervenção/ano; R$ 480,00.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
              {
                marginTop: 12,
                columns: [
                  {
                    image: 'security',
                    width: 18,
                  },
                  {
                    text: 'Segurança e Vigilância',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Se, em decorrência de sinistro devidamente coberto pela apólice, a Residência Segurada ficar vulnerável, com danos às fechaduras, janelas, portas ou qualquer outro ponto de acesso ao imóvel, a SEGURADORA providenciará um vigilante, se necessário.\nLimites: R$ 350,00/ utilização; 2 utilizações/ ano; até 3 dias/ utilização.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
            ],
            pageBreak: 'before',
          },
          /* Pág. 12 */
          {
            marginTop: 18,
            stack: [
              {
                columns: [
                  {
                    image: 'truck',
                    width: 25,
                  },
                  {
                    text: 'Mudança e Guarda Provisória de Móveis',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Quando for verificada a impossibilidade de habitação da Residência Segurada em consequência da comprovação de danos estruturais, de característica urgente, deve-se seguir o sinistro devidamente coberto pela apólice e, existindo a necessidade de reparos ou reformas que exijam a mudança temporária dos moradores, a SEGURADORA se encarregará do frete da mudança até a nova moradia provisória, e ainda da guarda dos objetos e bens que não possam ser transferidos ou que não possam permanecer no local do risco.\nLimites: Limite: R$ 720,00/utilização; até 50 km; até 07 dias.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
              {
                marginTop: 12,
                columns: [
                  {
                    marginBottom: 2,
                    image: 'petResidence',
                    width: 20,
                  },
                  {
                    text: 'Pet Residência - Hospedagem de Animais de Estimação',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Se, em consequência de sinistro ocorrido na Residência Segurada for necessária à desocupação do imóvel e não houver nenhum adulto ou familiar do Segurado que possa tomar conta dos seus animais domésticos, a SEGURADORA prestará auxílio subsistenciais ao pet, em local apropriado para esta finalidade. A RESPONSABILIDADE DA seguradora LIMITA-SE AOS CUSTOS COM A GUARDA DOS ANIMAIS, OBSERVADOS OS LIMITES DESCRITOS NESTAS CONDIÇÕES ESPECÍFICAS. ESTE SERVIÇO ABRANGE APENAS OS CUSTOS COM ESTADIA DOS ANIMAIS, ESTANDO EXPRESSAMENTE EXCLUÍDOS DE SUA RESPONSABILIDADE QUAISQUER OUTROS GASTOS, TAIS COMO VETERINÁRIO, TOSA, BANHO, ETC.\nLimites: 1 utilização/ano; até 05 dias; R$ 100,00 /dia.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
              {
                marginTop: 12,
                columns: [
                  {
                    marginTop: 4,
                    image: 'petPortion',
                    width: 25,
                  },
                  {
                    text: 'Pet Residência - Envio de Ração',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Se, em decorrência de sinistro devidamente coberto pela apólice, o dono do Animal Assistido se encontrar impedido de ir pessoalmente comprar ração, a SEGURADORA providenciará a entrega no domicílio do Segurado, observados os limites descritos nestas Condições Específicas. Para tal, o Segurado deverá entrar em contato com a Central de Atendimento. OS CUSTOS REFERENTES À RAÇÃO SÃO DE RESPONSABILIDADE DO SEGURADO.\nLimites: 1 intervenção/ano; R$ 200,00.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
              {
                marginTop: 12,
                columns: [
                  {
                    marginTop: 2,
                    image: 'ambulance',
                    width: 25,
                  },
                  {
                    text: 'Pet Residência - Transporte Veterinário',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Garante, em decorrência de sinistro devidamente coberto pela apólice, a remoção do Animal Assistido, através do meio de transporte mais adequado, até a clínica mais próxima, observados os limites descritos nestas Condições Específicas. DESTACA-SE QUE COMPETE AO SEGURADO ARCAR COM O MONTANTE DEVIDO AOS SERVIÇOS VETERINÁRIOS, EXAMES, MEDICAMENTOS ETC., EXCLUINDO-SE SOMENTE O ITEM SUPRACITADO. \nLimites: 1 intervenção/ano; R$ 200,00.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
            ],
            pageBreak: 'before',
          },
          /* Pág. 13 */
          {
            marginTop: 18,
            stack: [
              {
                columns: [
                  {
                    image: 'airplane',
                    width: 25,
                  },
                  {
                    text: 'Regresso Antecipado',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Se o Segurado se encontrar em viagem e, em decorrência de sinistro devidamente coberto pela apólice, for necessário seu regresso à Residência Segurada, a S Cia. de Seguros colocará à sua disposição uma passagem aérea para o seu retorno ao local do evento. ESTE SERVIÇO SOMENTE PODERÁ SER ACIONADO QUANDO A LOCALIZAÇÃO DO SEGURADO FOR SUPERIOR A 300 (TREZENTOS) QUILÔMETROS DA RESIDÊNCIA SEGURADA, OU SE O TRAJETO RODOVIÁRIO TIVER DURAÇÃO SUPERIOR A 5 (CINCO) HORAS.\nLimites: Franquia quilométrica: 300 km; Passagem de Classe Econômica.1 utilização/ano; R$ 500,00 por utilização.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
              {
                marginTop: 12,
                columns: [
                  {
                    image: 'carRecovery',
                    width: 25,
                  },
                  {
                    text: 'Recuperação do Veículo',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Em complementação ao serviço descrito no subitem 5.7- REGRESSO ANTECIPADO, a SEGURADORA providenciará o retorno do Segurado ao local onde deixou o veículo para removê-lo, pelo meio que julgar mais adequado. Limites: Franquia quilométrica: 300 km; Passagem de Classe Econômica. 1 utilização/ano; R$ 500,00 por utilização.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
              {
                marginTop: 12,
                columns: [
                  {
                    image: 'domesticServices',
                    width: 25,
                  },
                  {
                    text: 'Serviços domésticos provisórios',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Em caso de dano físico de natureza grave do(a) segurado(a) responsável pelos afazeres domésticos da Residência Segurada que resulte em hospitalização superior a 7 (sete) dias, a SEGURADORA providenciará o reembolso das despesas com a contratação de uma faxineira mediante apresentação de recibos e/ou notas fiscais que comprovem as despesas gastas. A RESPONSABILIDADE DA SEGURADORA RESTRINGE-SE AOS CUSTOS DE CONTRATAÇÃO DA FAXINEIRA, OBSERVADOS OS LIMITES DESCRITOS NESTAS CONDIÇÕES ESPECÍFICAS.\nLimites: 1 utilização/ano; R$ 400,00.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
              {
                marginTop: 12,
                columns: [
                  {
                    image: 'childCare',
                    width: 20,
                  },
                  {
                    text: 'Cuidado de crianças',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Se, em consequência de sinistro ocorrido na Residência Segurada, o Segurado ou beneficiário sofrer dano físico de natureza grave e tiver que permanecer hospitalizado por período superior a 1 (um) dia, e se não houver nenhum outro adulto ou familiar que possa tomar conta de seus filhos menores de 14 (quatorze) anos, a SEGURADORA submete-se no auxilio e nos cuidados destes menores recorrendo ao reembolso de baby-sitter. A RESPONSABILIDADE DA SEGURADORA LIMITA-SE AO REEMBOLSO DO SERVIÇO DA BABY-SITTER OBSERVADOS OS LIMITES DESCRITOS NESTAS CONDIÇÕES ESPECÍFICAS.\nLimites: 1 utilização/ano; R$ 200,00.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
            ],
            pageBreak: 'before',
          },
          /* Pág. 14 */
          {
            marginTop: 18,
            stack: [
              {
                columns: [
                  {
                    image: 'ambulance',
                    width: 25,
                  },
                  {
                    text: 'Remoção inter hospitalar',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Se, em decorrência de sinistro devidamente coberto pela apólice resultarem feridos em que, após serem prestados os primeiros socorros pelas autoridades públicas competentes, seja verificada pela equipe médica responsável a necessidade de remoção do Segurado e seus dependentes para uma unidade hospitalar mais apropriada, a S providenciará esta remoção pelo meio de transporte mais adequado para a situação. AS DESPESAS DESTE SERVIÇO ESTÃO LIMITADAS AO CUSTO DO MEIO DE TRANSPORTE UTILIZADO NA REMOÇÃO INTER HOSPITALAR.\nLimites: 1 utilização/ano; R$ 20.000,00.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
            ],
            pageBreak: 'before',
          },
          /* Pág. 15 */
          {
            marginTop: 18,
            stack: [
              {
                columns: [
                  {
                    marginBottom: 4,
                    image: 'fire',
                    width: 18,
                  },
                  {
                    text: 'Incêndio, queda de raio e explosão',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Esta Seguradora responderá, até o Limite Máximo de Indenização expressamente fixado pelo Segurado para a presente cobertura, pelas perdas e/ou danos materiais causados aos bens descritos nesta apólice por:\na) Incêndio, inclusive aqueles decorrentes de tumultos, greves e lock-out;\nb) Queda de raio ocorrido dentro da área do terreno ou edifício onde estiverem localizados os bens segurados;\nc) Explosão ou implosão de qualquer natureza, onde quer que se tenha originado. São também indenizáveis por esta cobertura as perdas e/ou danos materiais decorrentes de:\na) desmoronamento diretamente resultante de riscos cobertos;\nb) impossibilidade de remoção ou proteção dos salvados, por motivos de força maior; \nc) providências tomadas para o combate à propagação dos riscos cobertos;\nd) deterioração dos bens segurados guardados em ambientes refrigerados, resultante exclusivamente de paralisação do aparelhamento de refrigeração, em decorrência dos riscos cobertos e ocorridos dentro da área do estabelecimento segurado; Incluem-se entre os prejuízos indenizáveis os desembolsos efetuados pelo segurado para o desentulho do local sinistrado, conforme o valor ou o percentual do LMI desta cobertura estabelecido nesta apólice.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
              {
                marginTop: 12,
                columns: [
                  {
                    marginBottom: 2,
                    image: 'plug',
                    width: 18,
                  },
                  {
                    text: 'Danos elétricos',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Esta Seguradora responderá, até o Limite Máximo de Indenização expressamente fixado pelo Segurado para a presente cobertura, pelas perdas e/ou danos físicos diretamente causados a quaisquer máquinas, equipamentos ou instalações eletrônicas ou elétricas devido a variações anormais de tensão, curto-circuito, arco voltaico, calor gerado acidentalmente por eletricidade, descargas elétricas, eletricidade estática ou qualquer efeito ou fenômeno de natureza elétrica, inclusive a queda de raio ocorrida fora do local segurado.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
              {
                marginTop: 12,
                columns: [
                  {
                    marginTop: 2,
                    image: 'breakingGlass',
                    width: 18,
                  },
                  {
                    text: 'Quebra de vidros',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Esta Seguradora responderá, até o Limite Máximo de Indenização expressamente fixado pelo Segurado para a presente cobertura, pelas perdas e/ou danos materiais sofridos por vidros regularmente existentes e instalados em portas, janelas, vitrinas, balcões e mesas no(s) local(is) segurado(s) descrito(s) nesta apólice, em consequência de:\na) quebra de vidros, causada por imprudência ou culpa de terceiros, ou por ato involuntário do segurado, de membros de sua família ou de seus empregados e prepostos;\nb) quebra de vidros resultante da ação de calor artificial ou de chuva de granizo. Consideram-se garantidas, ainda, as despesas decorrentes das seguintes medidas:\na) reparo ou reposição dos encaixes dos vidros quando atingidos pelo sinistro ou remoção, reposição ou substituição de obstruções, exceto janelas, paredes e aparelhos quando necessário ao serviço de reparo ou substituição dos vidros danificados;\nb) instalação provisória de vidros ou vedação nas aberturas que contenham os vidros danificados, durante o tempo necessário ao seu reparo ou à substituição.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
            ],
            pageBreak: 'before',
          },
          /* Pág. 16 */
          {
            marginTop: 18,
            stack: [
              {
                columns: [
                  {
                    image: 'coin',
                    width: 25,
                  },
                  {
                    text: 'Perda ou pagamento de aluguel',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Esta Seguradora responderá, até o Limite Máximo de Indenização expressamente fixado pelo Segurado para a presente cobertura, pelas despesas de aluguel e demais despesas contratuais, caso fique o segurado impossibilitado do uso e/ou ocupação do local do risco em decorrência de sinistro coberto pela Cobertura Básica Obrigatória da presente apólice:\na) Caso o Segurado seja o proprietário do imóvel: Cobre a perda do aluguel e demais despesas contratuais, se o imóvel estiver alugado e o contrato de locação não obrigar a continuidade do pagamento do locatário após a ocorrência do sinistro. A despesa com aluguel e demais despesas contratuais que o Segurado tiver de pagar, a terceiro(s), se for compelido a alugar outro prédio para nele se instalar.\nb) Caso o Segurado seja o Locatário do Imóvel: Cobre o pagamento do aluguel e demais despesas contratuais ao proprietário do imóvel, se o contrato de locação obrigar a continuidade do seu pagamento após a ocorrência do sinistro. A indenização devida será paga em prestações mensais, calculadas tomando-se por base o Limite Máximo de Responsabilidade e o período indenitário para o qual foi contratada a cobertura. As prestações mensais referentes ao aluguel serão pagas durante o período de reparos ou de reconstrução do imóvel sinistrado, até o limite do período indenitário, não podendo, em caso algum, o montante de cada uma delas exceder o aluguel mensal legalmente auferido. O período indenitário terá início na data a partir da qual ocorrer a perda efetiva de aluguel ou iniciar o pagamento do aluguel à terceiros e sua duração estará limitada conforme opção do Segurado no ato da contratação, não podendo ultrapassar o máximo de 06 (seis) meses.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
              {
                marginTop: 12,
                columns: [
                  {
                    marginBottom: 4,
                    image: 'lock',
                    width: 18,
                  },
                  {
                    text: 'Roubo ou furto qualificado',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Esta Seguradora responderá até o Limite Máximo de Indenização expressamente fixado pelo segurado para a presente cobertura, pelas perdas e/ou danos materiais causados aos bens de sua residência habitual descritos nesta apólice, por:\na) roubo ou furto qualificado, conforme definido no inciso I do artigo 155 do Código Penal;\nb) extorsão, de acordo com a definição do Artigo n.o 158 do Código Penal. São também indenizáveis por esta cobertura as perdas e/ou danos materiais decorrentes de:\na) impossibilidade de remoção ou proteção dos salvados, por motivos de força maior;\nb) providências tomadas para o combate à propagação dos riscos cobertos;\nc) Danos causados a portas e janelas, bem como danos às fechaduras e outras partes do imóvel, onde os bens cobertos encontram-se localizados, quer o furto qualificado tenha se consumado ou não ou tenha se caracterizado como simples tentativa.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
            ],
            pageBreak: 'before',
          },
          /* Pág. 17 */
          {
            marginTop: 18,
            stack: [
              {
                columns: [
                  {
                    image: 'users',
                    width: 25,
                  },
                  {
                    text: 'Responsabilidade Civil familiar',
                    style: 'topic',
                  },
                ],
              },
              {
                layout: 'noBorders',
                table: {
                  widths: '*',
                  body: [
                    [
                      {
                        text: 'Esta Seguradora responderá, até o Limite Máximo de Indenização expressamente fixado pelo Segurado para a presente cobertura, pelo reembolso ao Segurado das quantias pelas quais vier a ser responsável civilmente, em sentença judicial transitada em julgado ou em acordo autorizado de modo expresso pela Seguradora, pelos danos corporais e/ou materiais causados a terceiros, desde que os danos tenham ocorridos durante a vigência deste contrato, e decorram exclusivamente dos seguintes riscos:\na) queda de objetos ou seu lançamento em local indevido;\nb) ações ou omissões do próprio Segurado, de seu cônjuge, de seus filhos menores que estiverem sob a sua autoridade e em sua companhia, e/ou de empregados domésticos no exercício do trabalho que lhes competir, ou em razão deles, ainda que ocorridas no exterior do imóvel residencial do Segurado;\nc) danos causados por animais domésticos pelos quais é o Segurado responsável, ainda que ocorridos no exterior do imóvel residencial do Segurado;\nd) existência, uso e conservação do imóvel ocupado pelo Segurado. Dentro do limite máximo previsto no contrato de seguro, quando contratado, a Seguradora responderá, também, pelas custas judiciais do foro civil e pelos honorários de advogados nomeados.',
                        style: 'textBox',
                      },
                    ],
                  ],
                },
              },
            ],
            pageBreak: 'before',
          },
        ],
        images: {
          cover: benvirCoverUrl,
          printBlue: benvirPrintBlueUrl,
          printYellow: benvirPrintYellowUrl,
          printOrange: benvirPrintOrangeUrl,
          printPurple: benvirPrintPurpleUrl,
          logo: benvirLogoDocumentUrl,
          badgesInfo: badgesInfoCondominiumUrl,
          rectangle: rectangleUrl,
          lineThead: lineTheadUrl,
          allSegLogo: allSegLogoUrl,
          starBullet: starBulletUrl,
          key: keyUrl,
          lightning: lightningUrl,
          plumber: plumberUrl,
          unblocker: unblockerUrl,
          microwave: microwaveUrl,
          airConditioning: airConditioningUrl,
          bucket: bucketUrl,
          phone: phoneUrl,
          eletrician: eletricianUrl,
          petFuneral: petFuneralUrl,
          petProtection: petProtectionUrl,
          glazier: glazierUrl,
          accomodation: accomodationUrl,
          cleaning: cleaningUrl,
          security: securityUrl,
          truck: truckUrl,
          petResidence: petResidenceUrl,
          petPortion: petPortionUrl,
          ambulance: ambulanceUrl,
          airplane: airplaneUrl,
          carRecovery: carRecoveryUrl,
          domesticServices: domesticServicesUrl,
          childCare: childCareUrl,
          fire: fireUrl,
          plug: plugUrl,
          breakingGlass: breakingGlassUrl,
          coin: coinUrl,
          lock: lockUrl,
          users: usersUrl,
        },
        styles: {
          tableHeaderCoverages: {
            bold: true,
            fontSize: 13,
            color: 'black',
            margin: [0, 15, 0, 0],
          },
          textBox: {
            fontSize: 10,
            fillColor: '#EEF4F2',
            margin: [5, 5, 5, 5],
          },
          // textBoxNoSpacing :
          title: {
            fontSize: 22,
            bold: true,
            marginBottom: 34,
          },
          topic: {
            bold: true,
            marginLeft: 8,
            marginBottom: 15,
          },
        },
        defaultStyle: {
          font: 'OpenSans',
          fontSize: 14,
        },
      };

      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      pdfMake.fonts = {
        OpenSansLight: {
          normal: 'OpenSans-Light.ttf',
          italics: 'OpenSans-LightItalic.ttf',
          bold: 'OpenSans-SemiBold.ttf',
          bolditalics: 'OpenSans-SemiBoldItalic.ttf',
        },
        OpenSans: {
          normal: 'OpenSans-Regular.ttf',
          italics: 'OpenSans-Italic.ttf',
          bold: 'OpenSans-Bold.ttf',
          bolditalics: 'OpenSans-BoldItalic.ttf',
        },
      };

      const createdPdf = pdfMake.createPdf(doc);

      setPdfFile(createdPdf);

      if (saveBlob) {
        createdPdf.getBlob((file) => {
          // console.log(file);
          saveBlob(file);

          pdfDoneRef.current = true;
        });
      }
    };

    loadImages();

    /* createdPdf.getBlob((file) => {
      // console.log(file);
      //saveBlob(file);

      pdfDoneRef.current = true;
    }); */
  }, [condominiumData, coverages, coverageUnitMonthlyValue, saveBlob]);

  const generatePdf = useCallback(() => {
    if (!pdfFile) return;

    // console.log(pdfFile);
    // console.log(document);
    pdfFile.open({}, window.open('', '_blank'));
  }, [pdfFile]);

  return (
    <Button
      /* width="130px" */
      // colorScheme="green"
      type="button"
      /* isDisabled={!!condominiumId}
      isLoading={isSubmitting} */
      onClick={() => generatePdf()}
    >
      Download
    </Button>
  );
};

export default OptimizedProposalReport;
