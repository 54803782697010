import React, { useEffect, useState } from 'react';
import { IconType } from 'react-icons';
import {
  FiDollarSign,
  FiPieChart,
  FiBriefcase,
  FiSettings,
} from 'react-icons/fi';
import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom';

import { Box, Button, Flex, Grid, Text } from '@chakra-ui/react';
import buildingIcon from 'assets/icons/building.svg';
import tieManIcon from 'assets/icons/tie_man.svg';
import { useAuth } from 'hooks/useAuth';
import useCan, { Roles } from 'hooks/useCan';
import { translateRole } from 'utils/translateRole';

import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';
import { FlexSidebar, GridLayout } from './styles';

export interface Menu {
  path: string[];
  name: string;
  Icon?: IconType;
  svg?: string;
  allowedRoles?: Roles[];
}

const ProtectedLayout: React.FC = () => {
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const { user, signOut } = useAuth();
  const can = useCan();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setToggleSidebar(false);
  }, [location.pathname]);

  /* Adicionar aqui novos itens de menu */
  const menu: Menu[] = [
    {
      path: ['dashboard'],
      name: 'Meu Painel',
      Icon: FiPieChart,
    },
    {
      path: ['cotacoes'],
      name: 'Cotações',
      Icon: FiDollarSign,
    },
    {
      path: ['meus-negocios'],
      name: 'Meus negócios',
      Icon: FiBriefcase,
    },
    {
      path: ['corretores'],
      name: 'Corretores',
      svg: tieManIcon,
      allowedRoles: ['admin', 'consultancy'],
    },
    {
      path: ['assessorias'],
      name: 'Assessorias',
      svg: buildingIcon,
      allowedRoles: ['admin'],
    },
    {
      path: ['configuracoes'],
      name: 'Configurações',
      Icon: FiSettings,
      allowedRoles: ['admin'],
    },
  ];

  const menuItemActive = menu.find((m) =>
    location.pathname.includes(`/${m.path}`),
  );

  // Verificar se usuário está logado
  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <GridLayout $active={toggleSidebar}>
      <FlexSidebar $active={toggleSidebar}>
        <Flex flexDir="column" height="full">
          <Header
            showSidebar={toggleSidebar}
            toggleSidebar={(toggle) => setToggleSidebar(toggle)}
          />
          <Sidebar menu={menu} pathname={location.pathname} />
          <Footer />
        </Flex>
      </FlexSidebar>

      <Box
        id="main"
        display={toggleSidebar ? 'none' : 'block'}
        height="full"
        bg="white"
        pt={4}
        pb={8}
        pl={['60px', '60px', 8]}
        pr={8}
      >
        {!menuItemActive?.allowedRoles || can(menuItemActive?.allowedRoles) ? (
          <Outlet />
        ) : (
          'Acesso Negado!'
        )}
      </Box>
    </GridLayout>
  );
};

export default ProtectedLayout;
