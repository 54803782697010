import React, { useState, useCallback, useMemo } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import {
  Button,
  Input as InputChakra,
  InputProps as InputChakraProps,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  Icon,
  InputRightElement,
  InputRightAddon,
} from '@chakra-ui/react';
import { Field, FieldInputProps, FormikProps } from 'formik';
import {
  cpfMask,
  decimalMask,
  phoneMask,
  cepMask,
  cnpjMask,
  percentageMask,
} from 'utils/masks';

interface InputProps extends InputChakraProps {
  name: string;
  placeholder?: string;
  label?: string;
  useTrim?: boolean;
  textArea?: boolean;
  last?: boolean;
  isPassword?: boolean;
  mask?: 'cnpj' | 'cpf' | 'cep' | 'decimal' | 'phone' | 'percent';
  height?: string | number;
  error?: string;
  touched?: boolean;
  rightAddon?: string;
  onBlurCustom?(): void;
}

interface InputFocus {
  backgroundColor: string;
  borderWidth: number;
  borderColor: string;
}

interface FormData {
  [key: string]: string;
}

const Input: React.FC<InputProps> = ({
  name,
  placeholder,
  last,
  isPassword,
  label,
  height,
  textArea = false,
  useTrim = false,
  mask,
  type,
  error,
  touched,
  rightAddon,
  onBlurCustom,
  ...rest
}) => {
  const [viewPass, setViewPass] = useState(false);
  const [focusStyle, setFocusStyle] = useState<InputFocus | null>(
    {} as InputFocus,
  );
  const hasError = useMemo(() => !!error && touched, [error, touched]);

  // const hasError = useMemo(() => !!error && touched, [error, touched]);

  function handleViewPass() {
    setViewPass((state) => !state);
  }

  const handleCustomText = useCallback(
    (form: FormikProps<FormData>, value: string) => {
      // if (!onChangeText) return;

      const text = useTrim ? value.trim() : value;

      // console.log(telefoneMask(text));
      // console.log(value, name);

      switch (mask) {
        case 'cnpj':
          form.setFieldValue(name, cnpjMask(text));
          break;
        case 'cpf':
          form.setFieldValue(name, cpfMask(text));
          break;
        case 'phone':
          form.setFieldValue(name, phoneMask(text));
          break;
        case 'cep':
          form.setFieldValue(name, cepMask(text));
          break;
        case 'decimal':
          form.setFieldValue(name, decimalMask(text));
          break;
        case 'percent':
          form.setFieldValue(name, percentageMask(text));
          break;
        default:
          form.setFieldValue(name, decimalMask(text));
      }
    },
    [name, useTrim, mask],
  );

  return (
    <Field name={name}>
      {({
        field,
        form,
      }: {
        field: FieldInputProps<string>;
        form: FormikProps<FormData>;
      }) => (
        <FormControl isInvalid={hasError}>
          {label && <FormLabel>{label}</FormLabel>}

          <InputGroup>
            {/* LeftInput */}

            <InputChakra
              {...field}
              {...rest}
              type={!!isPassword && !viewPass ? 'password' : type || 'text'}
              placeholder={placeholder}
              onChange={(el) => {
                field.onChange(el);
                !!mask && handleCustomText(form, el.target.value);
              }}
              onBlur={(el) => {
                field.onChange(el);
                !!onBlurCustom && onBlurCustom();
              }}
            />
            {/* Right Input */}
            {isPassword && (
              <InputRightElement width="4.5rem">
                <Button
                  variant="ghost"
                  color="gray.500"
                  height="1.75rem"
                  size="sm"
                  onClick={() => handleViewPass()}
                >
                  <Icon as={viewPass ? FiEyeOff : FiEye} size={22} />
                </Button>
              </InputRightElement>
            )}
            {rightAddon && <InputRightAddon>{rightAddon}</InputRightAddon>}
          </InputGroup>
          {hasError && <FormErrorMessage>{error}</FormErrorMessage>}
        </FormControl>
      )}
    </Field>
  );
};

export default Input;
