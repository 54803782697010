import iconCss from 'assets/file-icons/css.svg';
import iconDat from 'assets/file-icons/dat.svg';
import iconDefault from 'assets/file-icons/default.svg';
import iconDoc from 'assets/file-icons/doc.svg';
import iconEps from 'assets/file-icons/eps.svg';
import iconGif from 'assets/file-icons/gif.svg';
import iconJpg from 'assets/file-icons/jpg.svg';
import iconJs from 'assets/file-icons/js.svg';
import iconMp3 from 'assets/file-icons/mp3.svg';
import iconPdf from 'assets/file-icons/pdf.svg';
import iconPhp from 'assets/file-icons/php.svg';
import iconPng from 'assets/file-icons/png.svg';
import iconRaw from 'assets/file-icons/raw.svg';
import iconSvg from 'assets/file-icons/svg.svg';
import iconTxt from 'assets/file-icons/txt.svg';
import iconXls from 'assets/file-icons/xls.svg';
import iconXml from 'assets/file-icons/xml.svg';
import iconZip from 'assets/file-icons/zip.svg';

export type FileExtension =
  | 'png'
  | 'jpg'
  | 'jpeg'
  | 'doc'
  | 'docx'
  | 'gif'
  | 'dat'
  | 'css'
  | 'eps'
  | 'js'
  | 'mp3'
  | 'pdf'
  | 'php'
  | 'raw'
  | 'svg'
  | 'txt'
  | 'xls'
  | 'xml'
  | 'zip'
  | 'xslm'
  | 'default';

const icons = {
  png: iconPng,
  jpg: iconJpg,
  jpeg: iconJpg,
  docx: iconDoc,
  doc: iconDoc,
  gif: iconGif,
  dat: iconDat,
  css: iconCss,
  eps: iconEps,
  js: iconJs,
  mp3: iconMp3,
  pdf: iconPdf,
  php: iconPhp,
  raw: iconRaw,
  txt: iconTxt,
  xls: iconXls,
  xml: iconXml,
  zip: iconZip,
  xslm: iconXls,
  xlsx: iconXls,
  svg: iconSvg,
  default: iconDefault,
};

/* export function isFileExtension(string) {
  const fileExtensions: = [
    'png',
    'jpg',
    'jpeg',
    'doc',
    'gif',
    'dat',
    'css',
    'eps',
    'js',
    'mp3',
    'pdf',
    'php',
    'raw',
    'svg',
    'txt',
    'xls',
    'xml',
    'zip',
    'xslm',
    'default',
  ];
} */

export function getFileIconByExt(type: FileExtension): string {
  const typeFile = type || 'default';

  return icons[typeFile];
}

export function getPreviewFile(
  url: string,
  mime: string,
  extension: any,
): string {
  const mimeArr = mime?.split('/', 2);

  return (mimeArr && mimeArr[0]) === 'image'
    ? url
    : getFileIconByExt(extension);
}
