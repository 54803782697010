import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';

import { jwtDecode } from 'jwt-decode';
import api from 'services/api';

export interface User {
  id: number;
  name: string;
  // email: string;
  avatar?: string;
  role: string;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  signIn(email: string, password: string): Promise<void>;
  signOut(): void;
  // updateUser(updateData: Partial<User>): Promise<void>;
}

interface LoginResponse {
  accessToken: string;
  refreshToken: string;
}

interface JwtPayload {
  sub: number;
  name: string;
  avatar?: string;
  role: string;
  // expiration time
  exp: number;
  // issued at (emitido em)
  iat: number;
}

type AuthProviderProps = {
  children?: React.ReactNode;
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Benvir:access_token');
    const expiresToken = localStorage.getItem('@Benvir:expires_token');
    const user = localStorage.getItem('@Benvir:user');

    // console.log('user localstorage', localStorage);

    if (!expiresToken) {
      return {} as AuthState;
    }

    /* const unexpiredToken = isBefore(
      new Date(),
      new Date(JSON.parse(expiresToken)),
    ); */

    // Verificar isBefore acima
    const unexpiredToken = true;

    if (token && user && unexpiredToken) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      const parseUser = JSON.parse(user);
      api.defaults.headers.company = parseUser.company_id;

      return { token, user: JSON.parse(user) };
    }

    if (!unexpiredToken) {
      localStorage.removeItem('@Benvir:token');
      localStorage.removeItem('@Benvir:expires_token');
      localStorage.removeItem('@Benvir:user');
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async (email: string, password: string) => {
    const response = await api.post<LoginResponse>('login', {
      email,
      password,
    });

    const { accessToken, refreshToken } = response.data;

    const decoded = jwtDecode<JwtPayload>(accessToken);

    const user: User = {
      id: decoded.sub,
      name: decoded.name,
      role: decoded.role,
    };

    // const expiresToken = expires_in;

    localStorage.setItem('@Benvir:access_token', accessToken);
    localStorage.setItem('@Benvir:refresh_token', refreshToken);
    localStorage.setItem('@Benvir:expires_token', decoded.exp.toString());

    localStorage.setItem('@Benvir:user', JSON.stringify(user));

    /* Os seguintes cabeçalhos geram preflight por não se encaixarem como requisição simples.
    Agrega-se a isso o fato de utilizar o header Content-Type: application/json,
    apesar de que não é necessário para GET requests
 */

    setData({ token: accessToken, user });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Benvir:access_token');
    localStorage.removeItem('@Benvir:refresh_token');
    localStorage.removeItem('@Benvir:expires_token');
    localStorage.removeItem('@Benvir:user');

    setData({} as AuthState);
  }, []);

  /* const updateUser = useCallback(
    async (updateData: User) => {
      const userUpdate = {
        ...data.user,
        ...updateData,
      };

      localStorage.setItem('@Benvir:user', JSON.stringify(userUpdate));

      setData({
        token: data.token,
        user: userUpdate,
      });
    },
    [setData, data],
  ); */

  const value = useMemo(
    () => ({
      user: data.user,
      signIn,
      signOut,
    }),
    [data.user, signIn, signOut],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
