import React from 'react';
import { FiPower } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { Button, Flex, Text } from '@chakra-ui/react';
import { useAuth } from 'hooks/useAuth';
import { translateRole } from 'utils/translateRole';

const Footer: React.FC = () => {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  return (
    <Flex
      flexDir="column"
      alignItems="center"
      p={3}
      background="rgba(255, 255, 255, 0.7)"
      mx={2}
      my={2}
      borderRadius="16px"
    >
      <Text fontWeight="bold" fontSize="20px">
        {user.name}
      </Text>
      <Text fontSize="16px">{translateRole(user.role)}</Text>
      <Button
        mt={6}
        colorScheme="gray"
        onClick={() => {
          signOut();
          navigate('/');
        }}
      >
        <Flex alignItems="center">
          <FiPower size={24} />
          <Text ml={2}>Sair</Text>
        </Flex>
      </Button>
    </Flex>
  );
};

export default Footer;
