import React, { ReactNode, useEffect, useMemo } from 'react';
import Dropzone, { Accept, FileRejection } from 'react-dropzone';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import {
  Box,
  Button,
  Collapse,
  Flex,
  Tag,
  Text,
  useToast,
} from '@chakra-ui/react';
import { filesize } from 'filesize';

import { DropContainer, UploadMessage } from './styles';

interface UploadProps {
  onUpload: (files: File[]) => void;
  showFormat?: boolean;
  accept?: Accept;
  maxFiles?: number;
}

const Upload: React.FC<UploadProps> = ({
  onUpload,
  showFormat = false,
  accept,
  maxFiles,
}: UploadProps) => {
  const maxFileSize = process.env.REACT_APP_UPLOAD_MAX_SIZE
    ? Number.parseInt(process.env.REACT_APP_UPLOAD_MAX_SIZE, 10)
    : undefined;
  const [show, setShow] = React.useState(false);

  const addToast = useToast();

  const handleToggle = (): void => setShow(!show);

  function handleOnDropReject(rejects: FileRejection[]): void {
    if (maxFileSize) {
      rejects.forEach((reject) => {
        reject.errors.forEach((err) => {
          if (err.code === 'file-too-large') {
            addToast({
              title: `O arquivo ${reject.file.name} é muito grande`,
              position: 'top-right',
              isClosable: true,
              status: 'warning',
              description: `O limite de tamanho para upload de arquivos é de ${filesize(
                maxFileSize,
                { base: 10 },
              )}`,
            });
          } else if (err.code === 'file-invalid-type') {
            addToast({
              title: `O tipo do arquivo ${reject.file.name} não é permitido`,
              position: 'top-right',
              isClosable: true,
              status: 'error',
            });
          }
        });
      });
    }
  }

  function renderDragMessage(
    isDragActive: boolean,
    isDragRejest: boolean,
  ): ReactNode {
    const fileHint =
      maxFiles && maxFiles === 1 ? 'o arquivo' : 'o(s) arquivo(s)';
    // console.log(isDragRejest);
    if (!isDragActive) {
      return (
        <UploadMessage>
          {`Selecione ou arraste ${fileHint} aqui.`}
        </UploadMessage>
      );
    }

    if (isDragRejest) {
      return (
        <UploadMessage type="error">
          Alguns arquivos não são suportados
        </UploadMessage>
      );
    }

    return (
      <UploadMessage type="success">{`Solte ${fileHint} aqui`}</UploadMessage>
    );
  }

  const getColor = (
    isDragAccept: boolean,
    isDragReject: boolean,
    isFocused: boolean,
  ) => {
    if (isDragAccept) {
      return '#12a454';
    }
    if (isDragReject) {
      return '#e83f5b';
    }
    if (isFocused) {
      return '#2196f3';
    }
    return '#969cb3';
  };

  const allowedExtensions = useMemo((): ReactNode => {
    let extensions: string[] = [];

    if (!accept) {
      return (
        <Tag size="sm" m={1}>
          Todos
        </Tag>
      );
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, ext] of Object.entries(accept)) {
      extensions = [...extensions, ...ext.map((e) => e)];
    }

    return extensions.map((ex) => (
      <Tag size="sm" m={1} key={ex}>
        {ex}
      </Tag>
    ));
  }, [accept]);

  return (
    <>
      {showFormat && (
        <Flex flexDir="row-reverse">
          <Box>
            <Button
              leftIcon={show ? <FiEyeOff /> : <FiEye />}
              size="sm"
              onClick={handleToggle}
              mt={1}
              variant="ghost"
              colorScheme="teal"
              mb={1}
            >
              {show ? 'Ocultar' : 'Ver formatos permitidos'}
            </Button>
            <Collapse startingHeight={0} in={show}>
              <Flex flexDir="column">
                {/* <Text w="120px" fontSize="12px">
                  Formatos aceitos:
                </Text> */}
                <Flex flexWrap="wrap">{allowedExtensions}</Flex>
              </Flex>
            </Collapse>
          </Box>
        </Flex>
      )}
      <Dropzone
        accept={accept}
        onDropRejected={(rejectedFiles) => {
          handleOnDropReject(rejectedFiles);
        }}
        onDropAccepted={(acceptedFiles) => {
          onUpload(acceptedFiles);
        }}
        maxSize={maxFileSize}
        maxFiles={maxFiles}
        multiple={!maxFiles || maxFiles > 1}
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragReject,
          isDragAccept,
          isFocused,
        }) => (
          <DropContainer
            style={{
              borderColor: getColor(isDragAccept, isDragReject, isFocused),
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {renderDragMessage(isDragActive, isDragReject)}
          </DropContainer>
        )}
      </Dropzone>
    </>
  );
};

export default Upload;
