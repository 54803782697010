export default {
  global: {
    html: {
      minHeight: '100vh,',
    },
    body: {
      minHeight: '100vh',
      fontSize: '1rem',
      fontFamily: 'Roboto, system-ui, sans-serif',
    },
  },
  colors: {
    brand: {
      200: '#F2FF6E',
      400: '#F0BD00',
      500: '#FF8D20',
    },
    alternative: {
      purple: '#F0B1D7',
      blue: '#B9E7E4',
    },
  },
  fonts: {
    body: 'Roboto, Poppins, system-ui, sans-serif',
    maven: 'Maven Pro',
    open_sans: 'Open Sans',
    poppins: 'Poppins',
    roboto: 'Roboto',
  },
};
