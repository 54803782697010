import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  SimpleGrid,
  Switch,
  useToast,
} from '@chakra-ui/react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useAuth } from 'hooks/useAuth';
import { Broker } from 'modules/brokers/@types/broker';
import { Consultancy } from 'modules/consultancies/@types/consultancy';
import api from 'services/api';
import getAddress from 'services/via-cep';
import Input from 'shared/Input';
import Loading from 'shared/Loading';
import PageHeader from 'shared/PageHeader';
import Select, { Option } from 'shared/Select';
import { cepMask, cnpjMask, phoneMask } from 'utils/masks';
import states from 'utils/states';
import * as Yup from 'yup';

// Verificar se é necessário informar dados como data de nascimento e sexo
interface FormData {
  consultancy_id?: number;
  name: string;
  email: string;
  role: string;
  avatar: string;
  corporate_reason: string;
  cep: string;
  city: string;
  cnpj: string;
  phone: string;
  uf: string;
  address: string;
  password: string;
  password_confirmation: string;
  reset_password?: boolean;
}

const CreateBroker: React.FC = () => {
  const { user: userData } = useAuth();
  const isConsultancy = userData.role === 'consultancy';
  const { id } = useParams();
  const addToast = useToast();
  const navigate = useNavigate();
  const [broker, setBroker] = useState<Broker>();
  const [loading, setLoading] = useState(true);
  const [loadingOptions, setLoadingOptions] = useState(!isConsultancy);
  // const [consultancies, setConsultancies] = useState<Consultancy[]>([]);
  const [consultanciesOptions, setConsultanciesOptions] = useState<Option[]>(
    [],
  );
  const [togglePassword, setTogglePassword] = useState(false);

  // Para exibir a mensagem de validação ao clicar em salvar o campo deve estar no initial data
  const [initialValues, setInitialValues] = useState<FormData>({
    consultancy_id: isConsultancy ? userData.id : undefined,
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    role: 'broker',
    avatar: '',
    corporate_reason: '',
    cep: '',
    city: '',
    cnpj: '',
    phone: '',
    uf: '',
    address: '',
  });

  useEffect(() => {
    // Sem paginação
    if (!isConsultancy) {
      setLoadingOptions(true);
      api
        .get<Consultancy[]>('consultancies')
        .then((resp) => {
          const consultancies = resp.data;

          setConsultanciesOptions(
            consultancies.map((consult) => ({
              name: consult.corporate_reason,
              value: consult.user_id,
            })),
          );
        })
        .finally(() => setLoadingOptions(false));
    }

    if (!id) {
      setLoading(false);
      return;
    }

    // console.log(id);

    setLoading(true);
    api
      .get<Broker>(`brokers/${id}`)
      .then((resp) => {
        setBroker(resp.data);

        const {
          corporate_reason,
          cep,
          city,
          cnpj,
          phone,
          uf,
          address,
          user,
          consultancy,
        } = resp.data;

        // Adicionar a formatação de dados aqui, para cnpj, phone, etc.
        setInitialValues((state) => ({
          ...state,
          consultancy_id: consultancy.user_id,
          name: user.name,
          email: user.email,
          corporate_reason,
          cep: cepMask(cep),
          city,
          cnpj: cnpjMask(cnpj),
          phone: phoneMask(phone),
          uf,
          address,
        }));
      })
      .catch((err) => {
        // Retornando Not Found na mensagem
        addToast({
          title: 'Não foi possível encontrar os dados da corretora',
          // description: err.response?.data?.message || '',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
        navigate('/corretores');
      })
      .finally(() => setLoading(false));
  }, [id, addToast, navigate, isConsultancy]);

  const validationSchema = useMemo(() => {
    const validationRules = {
      consultancy_id: Yup.number().required(
        'Necessário selecionar uma assessoria',
      ),
      name: Yup.string().required('Campo obrigatório'),
      email: Yup.string()
        .required('Campo obrigatório')
        .email('O e-mail deve ser válido'),
      password: Yup.string()
        .required('Senha obrigatória')
        .min(6, 'A senha deve possuir no mínimo 6 caracteres'),
      password_confirmation: Yup.string()
        .required('Confirmação de senha obrigatória')
        .oneOf([Yup.ref('password')], 'As senhas não coincidem'),
      avatar: Yup.string().nullable(),
      corporate_reason: Yup.string().required('Campo obrigatório'),
      cnpj: Yup.string().required('Campo obrigatório'),
      phone: Yup.string()
        .required('Campo obrigatório')
        .min(14, 'Verifique o número informado')
        .max(15, 'Verifique o número informado'),
      cep: Yup.string()
        .required('Campo obrigatório')
        .min(9, 'Verifique o CEP informado'),
      city: Yup.string().required('Campo obrigatório'),
      uf: Yup.string().required('Campo obrigatório'),
      address: Yup.string().required('Campo obrigatório'),
    };

    const validationRulesNoPass = {
      ...validationRules,
      password: Yup.string().nullable(),
      password_confirmation: Yup.string().nullable(),
    };

    return Yup.object().shape(
      !id || togglePassword ? validationRules : validationRulesNoPass,
    );
  }, [id, togglePassword]);

  const handleSubmit = useCallback(
    (data: FormData, action: FormikHelpers<FormData>) => {
      const { consultancy_id, cep, cnpj, phone } = data;

      // console.log(data);

      const formattedData: FormData = {
        ...data,
        consultancy_id: Number(consultancy_id),
        cep: cep.replace(/\D/g, ''),
        cnpj: cnpj.replace(/\D/g, ''),
        phone: phone.replace(/\D/g, ''),
        reset_password: togglePassword,
      };

      // console.log(formattedData);
      if (!id) {
        api
          .post('brokers', formattedData)
          .then((resp) => {
            addToast({
              title: 'Corretora cadastrada com sucesso',
              status: 'success',
              position: 'top-right',
              isClosable: true,
            });

            navigate('/corretores');
          })
          .catch((err) => {
            addToast({
              title: 'Não foi possível cadastrar a corretora',
              description: err.response?.data?.message || '',
              status: 'error',
              position: 'top-right',
              isClosable: true,
            });
          })
          .finally(() => action.setSubmitting(false));
      } else {
        api
          .put(`brokers/${id}`, formattedData)
          .then((resp) => {
            addToast({
              title: 'Corretora atualizada com sucesso',
              status: 'success',
              position: 'top-right',
              isClosable: true,
            });

            navigate('/corretores');
          })
          .catch((err) => {
            addToast({
              title: 'Não foi possível atualizar a corretora',
              description: err.response?.data?.message || '',
              status: 'error',
              position: 'top-right',
              isClosable: true,
            });
          })
          .finally(() => action.setSubmitting(false));
        // lembrar de passar reset_password no valor de togglePassword
      }
    },
    [addToast, navigate, id, togglePassword],
  );

  return (
    <>
      {/* Corretora a ser visualizada */}
      <PageHeader
        title={id ? broker?.corporate_reason || '' : 'Nova corretora'}
        description="Cadastrar nova corretora"
        goBackPage
        loading={id ? loading : false}
      />
      {loading || loadingOptions ? (
        <Loading />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting, values, setValues }) => (
            <Form>
              {/* Função determinada de acordo com a página que o usuário
             está utilizando para cadastrar as informações */}
              <Input type="hidden" name="role" />
              <Box position="relative" padding="10">
                <Divider />
                <AbsoluteCenter px="4" bg="white" fontSize="20px">
                  Dados da conta
                </AbsoluteCenter>
              </Box>
              {/* Dados de usuário */}
              <SimpleGrid columns={2} spacing={4}>
                {/*   <Flex flexDir={['column', 'row']}> */}
                <Input
                  name="name"
                  label="Nome"
                  placeholder="Nome"
                  error={errors.name}
                  touched={touched.name}
                  autoFocus={!id}
                />
                <Input
                  name="email"
                  label="E-mail"
                  placeholder="E-mail"
                  error={errors.email}
                  touched={touched.email}
                />
                {/*  </Flex> */}

                {id && (
                  <>
                    <FormControl mt={2} display="flex" alignItems="center">
                      <FormLabel htmlFor="togglePass" mb="0">
                        Alterar senha?
                      </FormLabel>
                      <Switch
                        id="togglePass"
                        onChange={() => setTogglePassword(!togglePassword)}
                      />
                    </FormControl>
                    <Box />
                  </>
                )}

                {(!id || togglePassword) && (
                  <>
                    <Box>
                      <Input
                        name="password"
                        label="Senha"
                        placeholder="Senha"
                        isPassword
                        error={errors.password}
                        touched={touched.password}
                        // isDisabled={!togglePassword}
                      />
                    </Box>
                    <Input
                      label="Confirmação de senha"
                      name="password_confirmation"
                      placeholder="Confirme sua senha"
                      isPassword
                      error={errors.password_confirmation}
                      touched={touched.password_confirmation}
                      // isDisabled={!togglePassword}
                    />
                  </>
                )}
              </SimpleGrid>

              <Box position="relative" mt={5} padding="10">
                <Divider />
                <AbsoluteCenter px="4" bg="white" fontSize="20px">
                  Dados da empresa
                </AbsoluteCenter>
              </Box>
              {/* Dados de assessoria */}
              <SimpleGrid columns={2} spacing={4}>
                {/*   <Flex flexDir={['column', 'row']}> */}
                <Input
                  name="corporate_reason"
                  label="Razão Social"
                  placeholder="Razão Social"
                  error={errors.corporate_reason}
                  touched={touched.corporate_reason}
                />
                <Input
                  name="cnpj"
                  label="CNPJ"
                  placeholder="00.000.000/1000-00"
                  mask="cnpj"
                  error={errors.cnpj}
                  touched={touched.cnpj}
                />

                {!isConsultancy && (
                  <Select
                    name="consultancy_id"
                    label="Assessoria"
                    options={consultanciesOptions}
                    error={errors.consultancy_id}
                    touched={touched.consultancy_id}
                  />
                )}

                <Input
                  name="phone"
                  label="Telefone"
                  placeholder="(00) 00000-0000"
                  mask="phone"
                  error={errors.phone}
                  touched={touched.phone}
                />

                <Input
                  name="cep"
                  label="CEP"
                  placeholder="00000-000"
                  mask="cep"
                  error={errors.cep}
                  touched={touched.cep}
                  onBlurCustom={async () => {
                    const addressInfo = await getAddress(values.cep);

                    if (!addressInfo) {
                      return;
                    }

                    setValues({
                      ...values,
                      city: addressInfo.city,
                      uf: addressInfo.uf,
                      address: addressInfo.address,
                    });
                  }}
                />
                <Select
                  name="uf"
                  label="Estado"
                  options={states}
                  error={errors.uf}
                  touched={touched.uf}
                />
                <Input
                  name="city"
                  label="Cidade"
                  placeholder="Cidade"
                  error={errors.city}
                  touched={touched.city}
                />
                <Input
                  name="address"
                  label="Endereço"
                  placeholder="Endereço"
                  error={errors.address}
                  touched={touched.address}
                />
              </SimpleGrid>
              <Flex flexDir="row-reverse">
                <Button
                  width="100px"
                  type="submit"
                  isLoading={isSubmitting}
                  colorScheme="green"
                  mt={6}
                  size="lg"
                >
                  Salvar
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default CreateBroker;
