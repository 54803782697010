import React from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { Flex, Text, Button, Box, Image } from '@chakra-ui/react';
import { useAuth } from 'hooks/useAuth';
import useCan from 'hooks/useCan';

import { Menu } from '..';

import { BoxMenu, LinkItemMenu } from './styles';

interface SidebarProps {
  menu: Menu[];
  pathname: string;
}

const Sidebar: React.FC<SidebarProps> = ({ menu, pathname }) => {
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const can = useCan();

  return (
    <Flex flexDir="column" flex={1}>
      <BoxMenu>
        {menu
          .filter((item) => !item?.allowedRoles || can(item.allowedRoles))
          .map((item, key) => {
            const { path, name, Icon, svg } = item;

            return (
              <Flex
                mt={key ? 2 : 0}
                key={`${key.toString()}-${path}`}
                className="item-menu"
              >
                <LinkItemMenu to={path ? path[0] : 'dashboard'}>
                  <Flex
                    p={2}
                    alignItems="center"
                    /* justifyContent="center" */
                    className={
                      path && !!path.find((pt) => pathname.includes(`/${pt}`))
                        ? 'active'
                        : ''
                    }
                  >
                    {Icon ? (
                      <Icon size={24} />
                    ) : (
                      <Image width="24px" src={svg} />
                    )}
                    <Text ml={5}>{name}</Text>
                  </Flex>
                </LinkItemMenu>
              </Flex>
            );
          })}
        {/* <Flex justifyContent="center" mt="48px">
          <Button
            onClick={() => {
              signOut();
              navigate('/');
            }}
            rounded="-moz-initial"
            width="200px"
          >
            Desconectar
          </Button>
        </Flex> */}
      </BoxMenu>
    </Flex>
  );
};

export default Sidebar;
