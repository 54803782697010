import styled from 'styled-components';

export const MenuButton = styled.button`
  display: none;
  visibility: hidden;

  @media (max-width: 767px) {
    position: fixed;
    left: 2px;
    top: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: visible;
    //margin: 5px 20px 5px 0;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: transparent;
    transition: all 0.2s;
    ~ img {
      margin-left: 0.5rem;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }

    &:active {
      transform: scale(0.9);
    }
  }
`;
