export default {
  Select: {
    /* baseStyle: {
      field: {
        height: '56px',
      },
    }, */
    variants: {
      auth: {
        field: {
          bg: '#FFF',
          borderWidth: 1,
          borderColor: 'transparent',
          borderStyle: 'solid',
          _focus: {
            zIndex: 1,
            borderColor: '#B9E7E4 !important',
            boxShadow: '0 0 4px 1px #B9E7E4',
          },
          _hover: { borderColor: 'gray.500' },
        },
      },
      outline: {
        field: {
          bg: 'gray.100',
          borderWidth: 1,
          borderColor: 'transparent',
          borderStyle: 'solid',
          // padding: '1rem',
          _focus: {
            zIndex: 1,
            borderColor: '#B9E7E4 !important',
            boxShadow: '0 0 4px 1px #B9E7E4',
          },
          _hover: { borderColor: 'gray.300' },
        },
      },
    },
    sizes: {
      lg: {
        field: {
          fontSize: '1rem',
        },
      },
    },
    defaultProps: {
      variant: 'outline', // null here
      size: 'lg',
    },
  },
};
