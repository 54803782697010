export default {
  Input: {
    /* baseStyle: {
      field: {
        height: '56px',
      },
    }, */
    variants: {
      auth: {
        field: {
          bg: '#FFF',
          borderWidth: 1,
          borderColor: 'transparent',
          borderStyle: 'solid',
          padding: '1rem',
          _focus: {
            zIndex: 1,
            borderColor: 'teal.400 !important',
            boxShadow: '0 0 4px 1px #38B2AC',
          },
          _hover: { borderColor: 'teal.200' },
        },
      },
      outline: {
        field: {
          bg: 'gray.100',
          borderWidth: 1,
          borderColor: 'transparent',
          borderStyle: 'solid',
          padding: '1rem',
          _focus: {
            zIndex: 1,
            borderColor: 'teal.400 !important',
            boxShadow: '0 0 4px 1px #38B2AC',
          },
          _hover: { borderColor: 'teal.200' },
        },
      },
      reading: {
        field: {
          bg: 'white',
          borderWidth: 1,
          borderColor: 'gray.300',
          borderStyle: 'solid',
          padding: '1rem',
          _focus: {
            zIndex: 1,
            borderColor: 'gray.300',
          },
          _hover: { borderColor: 'gray.300' },
        },
      },
    },
    sizes: {
      lg: {
        field: {
          fontSize: '1rem',
        },
      },
    },
    defaultProps: {
      variant: 'outline', // null here
      size: 'lg',
    },
  },
};
