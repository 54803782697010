import { useAuth } from './useAuth';

export type Roles = 'admin' | 'consultancy' | 'broker';

type Response = (roles: Roles[] | Roles) => boolean;

const useCan = (): Response => {
  const { user } = useAuth();

  const userRole = user.role;

  return function can(roles) {
    if (!userRole) {
      return false;
    }

    const rolesArray = Array.isArray(roles) ? roles : [roles];

    const hasPermission = rolesArray.some((role) => userRole.includes(role));

    return hasPermission;
  };
};

export default useCan;
