import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import {
  Alert,
  AlertIcon,
  Box,
  Button,
  HStack,
  Stack,
  Td,
  Text,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { parseISO, format } from 'date-fns';
import { Consultancy } from 'modules/consultancies/@types/consultancy';
import api from 'services/api';
import AlertDialog from 'shared/AlertDialog';
import Loading from 'shared/Loading';
import PageHeader from 'shared/PageHeader';
import { PaginationProps } from 'shared/Pagination';
import Search from 'shared/Search';
import Table, { THeadProps } from 'shared/Table';
import { cnpjMask } from 'utils/masks';

interface ConsultancyPaginate {
  data: Consultancy[];
  meta: PaginationProps;
}

const Consultancies: React.FC = () => {
  const deleteRef = useRef(null);
  const addToast = useToast();
  const [consultancies, setConsultancies] = useState<ConsultancyPaginate>(
    {} as ConsultancyPaginate,
  );
  const [selectedConsultancy, setSelectedConsultancy] =
    useState<Consultancy | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const { onOpen, isOpen, onClose } = useDisclosure();

  const thead: THeadProps[] = [
    { title: 'ID' },
    { title: 'Nome' },
    { title: 'CNPJ' },
    { title: 'Responsável' },
    { title: 'Data de Registro' },
    { title: 'Ações', align: 'right' },
  ];

  useEffect(() => {
    setLoading(true);

    const { CancelToken } = axios;
    const source = CancelToken.source();

    api
      .get<ConsultancyPaginate>('consultancies', {
        params: {
          page,
          search,
        },
        cancelToken: source.token,
      })
      .then((resp) => {
        setConsultancies({
          ...resp.data,
          data: resp.data.data.map((consult) => ({
            ...consult,
            cnpj: cnpjMask(consult.cnpj),
          })),
        });
      })
      .finally(() => setLoading(false));

    return () => {
      /* Após chamar novamente a api, sem que se tenha resposta da primeira solicitação (pendente)
          o pedido anterior é cancelado e se realiza um novo pedido */
      source.cancel('Operação cancelada pelo usuário');
    };
  }, [page, search]);

  const handleDeleteConsultancy = useCallback(
    (id: number) => {
      setLoadingDelete(true);

      api
        .delete(`users/${id}`)
        .then(() => {
          setConsultancies((state) => ({
            meta: {
              ...state.meta,
              total: state.meta.total - 1,
            },
            data: state.data.filter((consult) => consult.user_id !== id),
          }));

          onClose();

          addToast({
            title: 'Assessoria deletada com sucesso',
            status: 'success',
            position: 'top-right',
            isClosable: true,
          });
        })
        .catch((err) => {
          addToast({
            title: 'Não foi possível deletar a assessoria',
            description: err.response?.data?.message || '',
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        })
        .finally(() => setLoadingDelete(false));
    },
    [addToast, onClose],
  );

  return (
    <>
      <PageHeader title="Assessorias" description="Lista de assessorias">
        <Button colorScheme="blue" onClick={() => navigate('cadastrar')}>
          <FaPlus />
          <Text ml={2}>Cadastrar</Text>
        </Button>
      </PageHeader>
      <Box mb={4}>
        <Search
          loading={loadingSearch}
          results={search ? consultancies.meta.total : 0}
          searchValue={(value) => {
            setPage(1);
            // console.log(value);
            setSearch(value);
          }}
          searchClear={() => {
            setPage(1);
            setSearch('');
          }}
          tooltipPlacement="top-start"
        />
      </Box>
      {loading ? (
        <Loading />
      ) : (
        <Box>
          {!consultancies?.data?.length ? (
            <Alert status="info">
              <AlertIcon />
              Nenhuma assessoria encontrada
            </Alert>
          ) : (
            <Table
              theadData={thead}
              loading={loading}
              pagination={{
                current_page: consultancies.meta.current_page,
                last_page: consultancies.meta.last_page,
                next: consultancies.meta.next,
                prev: consultancies.meta.prev,
                per_page: consultancies.meta.per_page,
                total: consultancies.meta.total,
              }}
              newPage={(pg) => setPage(pg)}
            >
              {consultancies.data.map((consult) => (
                <Tr key={consult.user_id}>
                  <Td>
                    <Text>{consult.user_id}</Text>
                  </Td>
                  <Td>
                    <Text>{consult.corporate_reason}</Text>
                  </Td>
                  <Td>
                    <Text>{consult.cnpj}</Text>
                  </Td>
                  <Td>
                    <Text>{consult.responsible}</Text>
                  </Td>
                  <Td>
                    <Text>
                      {format(
                        parseISO(consult.created_at),
                        "dd/MM/yy 'às' HH:mm",
                      )}
                    </Text>
                  </Td>
                  <Td>
                    <HStack spacing={2}>
                      <Button
                        type="button"
                        colorScheme="yellow"
                        variant="outline"
                        size="sm"
                        onClick={() => navigate(`editar/${consult.user_id}`)}
                      >
                        <FaEdit />
                      </Button>
                      <Button
                        type="button"
                        colorScheme="red"
                        variant="outline"
                        size="sm"
                        onClick={() => {
                          setSelectedConsultancy(consult);
                          onOpen();
                        }}
                        isLoading={loadingDelete}
                      >
                        <FaTrash />
                      </Button>
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Table>
          )}
        </Box>
      )}
      {selectedConsultancy && (
        <AlertDialog
          leastDestructiveRef={deleteRef}
          title="Apagar assessoria"
          description={`A assessoria ${selectedConsultancy.corporate_reason} e todos os seus dados de usuário no sistema serão apagados`}
          obs="Essa é uma ação irreversível"
          isOpen={isOpen}
          onSubmit={() => handleDeleteConsultancy(selectedConsultancy.user_id)}
          onClose={() => setSelectedConsultancy(null)}
          isLoading={loadingDelete}
        />
      )}
    </>
  );
};

export default Consultancies;
