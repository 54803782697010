import React from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import logo from 'assets/favicon.svg';

import LoadingLogo from './styles';

const Loading: React.FC = () => (
  <Flex justifyContent="center">
    <Flex flexDir="column" justifyContent="center" alignItems="center">
      <LoadingLogo src={logo} alt="logo" width="75px" />
      <Text align="center" mt={2} fontWeight="bold">
        Carregando...
      </Text>
    </Flex>
  </Flex>
);

export default Loading;
